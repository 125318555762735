@if (this.mode === 'all') {
<section class="flex flex-col">

    @if (section === 'licenses') {
    <p class="message-sm mb-5">The below table shows all the active licenses issued for the platform. Click on the edit
        button next to each item to view more details</p>
    <div class="d-flex">
        <button (click)="generateLicense()" class="btn btn-primary px-3 mb-5">Generate License</button>
    </div>
    } @else if (section === 'registrations') {
    <p class="message-sm mb-5">The below table shows all the pending license registrations for the platform. Click on
        the edit button next to each item to view more details</p>
    }

    <h6 class="my-3">
        @if (section === 'registrations') {
        Pending Registrations
        } @else if (section === 'licenses') {
        Active Licenses
        }
    </h6>

    @if (status !== Status.loading) {
    <table mat-table [dataSource]="licensesDataSource" class="table-auto w-full border-borderColor border">

        <!-- License name -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let row">
                <p class="message-sm">{{row.organization}}</p>
            </td>
        </ng-container>

        <!-- License Tier -->
        <ng-container matColumnDef="tier">
            <th mat-header-cell *matHeaderCellDef>Edition</th>
            <td mat-cell *matCellDef="let row">
                <span class="badge"
                    [ngClass]="{'badge-enterprise': row.tier === 'enterprise', 'badge-org': row.tier === 'pro', 'badge-free': row.tier === 'free' || row.tier === 'creator'}">{{row.tier}}</span>
            </td>
        </ng-container>

        <!-- License Status -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row">
                <div class="flex items-center">
                    @if (row.status === 'pending') {
                    <mat-icon class="text-yellow-500 mr-2">info</mat-icon>
                    } @else if (row.status === 'active') {
                    <mat-icon class="text-bgSuccess mr-2">check_circle</mat-icon>
                    } @else if (row.status === 'deleted') {
                    <mat-icon class="text-buttonDangerColor mr-2">error</mat-icon>
                    }
                    <span class="message-sm">{{row.status | titlecase}}</span>
                </div>
            </td>
        </ng-container>

        <!-- License Client ID -->
        <ng-container matColumnDef="client_id">
            <th mat-header-cell *matHeaderCellDef hidden>Client ID</th>
            <td mat-cell *matCellDef="let row" hidden>
                <span class="message-sm">{{row.client_id}}</span>
            </td>
        </ng-container>

        <!-- License Timestamp -->
        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>Issued On</th>
            <td mat-cell *matCellDef="let row">
                <span class="message-sm">{{row.timestamp | date:'medium'}}</span>
            </td>
        </ng-container>

        <!-- Actions -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
                <mat-icon (click)="viewLicense(row.entity, row.client_id)" class="cursor-pointer text-primary"
                    matTooltip="View License Details">edit</mat-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsLicense"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsLicense;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumnsLicense.length">
                No active licenses found
            </td>
        </tr>
    </table>
    } @else {
    <div class="flex items-center">
        <mat-spinner color="primary" [diameter]="20" class="mr-3"></mat-spinner>
        <span>Loading...</span>
    </div>
    }

    <mat-paginator #licensePaginator class="p-4 text-base" [pageSizeOptions]="[15, 25, 50]"
        [pageSize]="15" aria-label="Select page of licenses">
    </mat-paginator>
</section>
} @else if (this.mode === 'view') {
<app-license [isAdmin]="true" [condensed]="true" (back)="this.mode = 'all'"></app-license>
@if (status !== Status.loading) {

    @if(this.activeLicense.offline) {
        <div class="ring-1 ring-borderColor rounded p-3">
            <h6 class="text-base mb-1">License Setup</h6>
            <p class="text-sm mb-3">Download the offline license file in order to set-up the offline PIES Studio license.</p>
            <div class="flex gap-4">
                <button (click)="downloadLicense()" class="btn btn-primary px-3">Download License</button>
            </div>
        </div>
    }

<h6 class="my-3">Users</h6>
<table mat-table [dataSource]="userDataSource" class="table-auto w-full border-borderColor border">

    <!-- User Name -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row" class="message-sm">{{row.name}}</td>
    </ng-container>

    <!-- User Email -->
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let row" class="message-sm">{{row.email}}</td>
    </ng-container>

    <!-- License Status -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">
            <div class="flex items-center">
                @if (row.status === 'pending') {
                <mat-icon class="text-yellow-500 mr-2">info</mat-icon>
                } @else if (row.status === 'active') {
                <mat-icon class="text-bgSuccess mr-2">check_circle</mat-icon>
                } @else if (row.status === 'deleted') {
                <mat-icon class="text-buttonDangerColor mr-2">error</mat-icon>
                }
                <span class="message-sm">{{row.status | titlecase}}</span>
            </div>
        </td>
    </ng-container>

    <!-- Creation Timestamp -->
    <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef>Created On</th>
        <td mat-cell *matCellDef="let row">
            <span class="message-sm">{{row.created_at | date:'medium'}}</span>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsUser"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsUser;"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumnsUser.length">
            No active users found
        </td>
    </tr>
</table>
} @else {
<div class="flex items-center">
    <mat-spinner color="primary" [diameter]="20" class="mr-3"></mat-spinner>
    <span>Loading...</span>
</div>
}

<mat-paginator #userPaginator class="p-4 text-base" [pageSizeOptions]="[5, 10, 15]"
    [pageSize]="5" aria-label="Select page of licenses">
</mat-paginator>

<button class="btn btn-sm btn-outline my-3 px-3" (click)="this.mode = 'all'">
    <mat-icon inline class="mr-2">arrow_back</mat-icon>
    <span>Go Back</span>
  </button>
}