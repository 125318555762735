
export namespace OrgAction {
    export class GetOrganization {
        static readonly type = "[Org] Get Organization";
        constructor(public id: string) { }
    }

    export class GetUsers {
        static readonly type = "[Org] Get Users By Organization";
        constructor(public clientId: string) { }
    }

    export class AddUser {
        static readonly type = "[Org] Add User To Organization";
        constructor(public client_id: string, public paylaod: { id: string, role: string, purchase: boolean, mode: string, invitees: string[], }) { }
    }

    export class UpdateUser {
        static readonly type = "[Org] Update User For Organization";
        constructor(public client_id: string, public paylaod: { id: string, role: string, purchase: boolean }) { }
    }

    export class RemoveUser {
        static readonly type = "[Org] Remove User From Organization";
        constructor(public client_id: string, public user_id: string) { }
    }

    export class GetAddOnStatus {
        static readonly type = "[Org] Get Add On Status";
        constructor(public client_id: string, public type: string, public scope: string) { }
    }
}