import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { saveAs } from 'file-saver';
import { catchError, tap } from "rxjs";
import { AdminAction } from "../action/admin.action";
import { Status } from "../model/enum";
import { AdminStateModel } from "../model/license.model";
import { AdminService } from "../service/admin.service";
import { AlertService } from "../service/alert.service";


@State<AdminStateModel>({
    name: 'admin',
    defaults: {
        licenses: [],
        activeLicenseIndex: -1,
        status: Status.idle,
    },
})
@Injectable()
export class AdminState {

    constructor(
        private adminService: AdminService,
        private alertService: AlertService,
    ) { }

    @Selector()
    static getStatus(state: AdminStateModel) {
        return state.status;
    }

    @Selector()
    static getLicenses(state: AdminStateModel) {
        return state.licenses;
    }

    @Selector()
    static getActiveLicense(state: AdminStateModel) {
        if (state.activeLicenseIndex === -1) return undefined;

        return state.licenses[state.activeLicenseIndex];
    }

    @Action(AdminAction.GetAllLicenses)
    getAllLicenses(ctx: StateContext<AdminStateModel>, action: AdminAction.GetAllLicenses) {

        ctx.patchState({
            licenses: [],
            status: Status.loading,
        })

        return this.adminService.getAllLicenses(action.status).pipe(
            tap(response => {
                if (response) {
                    ctx.patchState({
                        licenses: response,
                        status: Status.success,
                    });
                }
            }),
            catchError(error => {

                ctx.patchState({
                    licenses: [],
                    status: Status.error,
                });

                return error;
            }),
        );
    }

    @Action(AdminAction.SetActiveLicense)
    setActiveLicenseIndex(ctx: StateContext<AdminStateModel>, action: AdminAction.SetActiveLicense) {
        const index = ctx.getState().licenses.findIndex(l => l.client_id === action.clientId);

        ctx.patchState({
            activeLicenseIndex: index,
        })
    }

    @Action(AdminAction.GenerateLicense)
    generateLicense(ctx: StateContext<AdminStateModel>, action: AdminAction.GenerateLicense) {

        ctx.patchState({
            status: Status.connecting,
        });

        return this.adminService.generateLicense(action.payload).pipe(
            tap(response => {
                if (response) {
                    ctx.patchState({
                        status: Status.active,
                    });

                    ctx.dispatch(new AdminAction.GetAllLicenses('active'));

                    this.alertService.success('Success');
                }
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.conflict,
                });

                throw error;
            }),
        )
    }

    @Action(AdminAction.DownloadOfflineLicense)
    downloadOfflineLicense(ctx: StateContext<AdminStateModel>, action: AdminAction.DownloadOfflineLicense) {
        ctx.patchState({
            status: Status.connecting,
        });

        return this.adminService.downloadOfflineLicense(action.clientId).pipe(
            tap(response => {
                if (response) {
                    saveAs(response, 'pies_studio.license');
                }
            }),
            catchError(error => {

                this.alertService.error("Something went wrong, please try again later");

                throw error;
            })
        );
    }
}