export namespace AdminAction {
    export class GetAllLicenses {
        static readonly type = "[Admin] Get All Licenses";
        constructor(public status: string) { }
    }

    export class GetLicense {
        static readonly type = "[Admin] Get One Licenses";
        constructor() { }
    }

    export class GetOrganization {
        static readonly type = "[Admin] Get Organization";
        constructor() { }
    }

    export class GetLicenseUsers {
        static readonly type = "[Admin] Get License Users";
        constructor() { }
    }

    export class SetActiveLicense {
        static readonly type = "[Admin] Set Active License";
        constructor(public clientId: string) { }
    }

    export class GenerateLicense {
        static readonly type = "[Admin] Generate License";
        constructor(public payload: { name: string, validity: Date, units: number, type: string, add_ons: {}, max_users: number }) { }
    }

    export class DownloadOfflineLicense {
        static readonly type = "[Admin] Download Offline License";
        constructor(public clientId: string) { }
    }
}