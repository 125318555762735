import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { first, Observable, Subject, takeUntil } from 'rxjs';
import { AdminAction } from '../../../../action/admin.action';
import { UserAction } from '../../../../action/user.action';
import { Status } from '../../../../model/enum';
import { License } from '../../../../model/license.model';
import { User } from '../../../../model/user.model';
import { AdminState } from '../../../../state/admin.state';
import { UserState } from '../../../../state/user.state';
import { MaterialModule } from '../../../utils/material.module';
import { LicenseComponent } from '../license/license.component';
import { GenerateComponent } from './generate/generate.component';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    MaterialModule,
    CommonModule,
    LicenseComponent,
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent {

  @Input() section: string = "";

  Status = Status;

  mode: string = 'all';

  status?: Status;

  displayedColumnsLicense: string[] = ['name', 'tier', 'status', 'timestamp', 'actions'];
  displayedColumnsUser: string[] = ['name', 'email', 'status', 'created_at'];

  licensesDataSource!: MatTableDataSource<License>;
  userDataSource!: MatTableDataSource<User>;
  activeLicense!: License;

  @Select(AdminState.getLicenses) private licenses$!: Observable<License[]>;
  @Select(AdminState.getActiveLicense) private activeLicense$!: Observable<License>;
  @Select(UserState.getUsers) private users$!: Observable<User[]>;
  @Select(AdminState.getStatus) private status$!: Observable<Status>;

  @ViewChild('licensePaginator') licensePaginator!: MatPaginator;
  @ViewChild('userPaginator') userPaginator!: MatPaginator;

  private destroy: Subject<boolean> = new Subject();

  constructor(
    private store: Store,
    private dialog: MatDialog,
  ) { }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  ngOnInit(): void {

    if (this.section === 'licenses') {
      this.store.dispatch(new AdminAction.GetAllLicenses('active'));
    } else if (this.section === 'registrations') {
      this.store.dispatch(new AdminAction.GetAllLicenses('pending'));
    }

    this.licenses$.pipe(takeUntil(this.destroy)).subscribe(response => {
      if (response) {
        this.licensesDataSource = new MatTableDataSource(response);
        this.licensesDataSource.paginator = this.licensePaginator;
      }
    });

    this.activeLicense$.pipe(takeUntil(this.destroy)).subscribe(response => {
      this.activeLicense = response;
    });

    this.users$.pipe(takeUntil(this.destroy)).subscribe(response => {
      if (response) {
        this.userDataSource = new MatTableDataSource(response);
        this.userDataSource.paginator = this.userPaginator;
      }
    });

    this.status$.pipe(takeUntil(this.destroy)).subscribe(response => {
      if (response) {
        this.status = response;
      }
    });
  }

  viewLicense(_: string, clientId: string) {
    this.store.dispatch(new AdminAction.SetActiveLicense(clientId)).pipe(first()).subscribe(_ => {
      this.mode = 'view';
    });

    this.store.dispatch(new UserAction.GetAllUsers(clientId));
  }

  generateLicense() {
    this.dialog.open(
      GenerateComponent,
      {
        minWidth: '30dvw',
        maxWidth: '30dvw',
        disableClose: true,
      },
    );
  }

  downloadLicense() {
    this.store.dispatch(new AdminAction.DownloadOfflineLicense(this.activeLicense.client_id));
  }
}
