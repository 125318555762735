@if (status == Status.loading || status === Status.connecting) {
    <div class="p-6 flex items-center">
        <mat-spinner [diameter]="15" class="mr-2"></mat-spinner>
        <p class="text-sm">Please wait...</p>
    </div>
} @else if(status === Status.success) {
    <div class="p-5 flex flex-col items-start">
        <div class="flex items-center mb-3">
            <mat-icon class="mr-2 text-bgSuccess">check_circle</mat-icon>
            <h6 class="text-base">Success</h6>
        </div>
        <p class="text-sm mb-5">You're all set! Your purchase was successful</p>
        <div class="w-full flex justify-end">
            <button class="btn btn-sm border text-btnPrimary" (click)="cancel()">Close</button>
        </div>
    </div>
} @else if(status === Status.error) {
    <div class="p-5 flex flex-col items-start">
        <div class="flex items-center mb-3">
            <mat-icon class="mr-2 text-btnDestructive">error</mat-icon>
            <h6 class="text-base">Error</h6>
        </div>
        <p class="text-sm mb-5">Something went wrong. Please try again in some time, or raise a support request through the
            <span class="font-medium">Requests</span> section
        </p>
        <div class="w-full flex justify-end">
            <button class="btn btn-sm border text-btnPrimary" (click)="cancel()">Close</button>
        </div>
    </div>
} @else {
    <div class="p-5">
        @if(this.data.mode === 'subscription') {
            <h6 class="mb-2">Purchase Credits</h6>
            <p class="text-sm mb-4">You can subscribe to a tier of your choice from the options provided below. Subcriptions are charged per active user, and can be billed annually or monthly.</p>
        
            <div class="flex items-center gap-4 mb-4">
                <div class="flex-1">
                    <label class="mb-1">Tier</label>
                    <ng-select (change)="updatePrice($event)" [clearable]="false" [items]="pricing" bindLabel="tier"></ng-select>
                </div>
                <div class="flex-1">
                    <label class="mb-1">Billing Cycle</label>
                    <ng-select [clearable]="false" [(ngModel)]="this.cycle" [items]="cycles" bindLabel="label" bindValue="value"></ng-select>
                </div>
            </div>
        
            @if (this.selectedTier !== undefined && this.userCount >= 0) {
                <h6 class="text-base mb-1">Pricing</h6>
                <p class="text-sm mb-3">Upon completing the purchase, you allow us to charged the following amount until cancelled based on the billing cycle selected. You can cancel the subscription any time after a successful purchase.</p>
        
                <div class="ring-1 ring-borderColor rounded mb-3 divide-y">
                    <div class="divide-x flex">
                        <div class="p-3 flex flex-col flex-1">
                            <label class="mb-1">Base Cost</label>
                            <h6 class="text-xl">
                                @if (this.cycle === 'month') {
                                    {{this.selectedTier.price_month | currency: 'USD':'symbol':'.0-2'}}
                                } @else {
                                    {{this.selectedTier.price_year | currency: 'USD':'symbol':'.0-2'}}
                                }
                            </h6>
                        </div>
                        <div class="p-3 flex flex-col flex-1">
                            <label class="mb-1">Users</label>
                            <h6 class="text-xl">
                                {{this.userCount}}
                            </h6>
                        </div>
                    </div>
                    <div class="flex flex-col p-3">
                        <label class="mb-1">Total Cost</label>
                        <h6 class="text-xl">
                            @if (this.cycle === 'month') {
                                {{this.selectedTier.price_month * this.userCount | currency: 'USD':'symbol':'.0-2'}}/month
                            } @else {
                                {{this.selectedTier.price_year * this.userCount | currency: 'USD':'symbol':'.0-2'}}/month, billed yearly
                            }
                        </h6>
                    </div>
                </div>
                <p class="text-sm mb-3 opacity-50">* All mentioned prices are exclusive of any taxes that may be applicable. Your subscription's final price will be presented to you on the checkout page.</p>
            } @else if(this.userCount === 0) {
                <h6 class="text-base mb-1">No Active Users</h6>
                <p class="text-sm mb-3">
                    You need at least one active user in your organization to purchase a subscription. Please add a user in your organization and try again.
                </p>
            }
        
            <button type="button" *ngIf="this.selectedTier !== undefined" class="btn btn-primary px-3 mb-2 w-full" (click)="proceed()">Proceed</button>
            <button type="button" class="btn btn-secondary px-3 w-full" (click)="cancel()">Cancel</button>
        } @else if(this.data.mode === 'cancel') {
            <h6 class="mb-2">Cancel Subscription</h6>
            <p class="text-sm mb-4">Are you sure you wish to cancel your add-on subscription? This action cannot be undone.</p>
         
            <button type="button" class="btn btn-primary px-3 mb-2 w-full" (click)="confirm()">Confirm</button>
            <button type="button" class="btn btn-secondary px-3 w-full" (click)="cancel()">Cancel</button>
        }
    </div>
}