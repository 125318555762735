<mat-drawer-container autosize class="w-full h-full bg-bgDefault">
    <mat-drawer #drawer
        class="menu-toolbar sidenav-secondary bg-bgDefault overflow-visible border-none shadow-none flex" mode="side"
        [opened]="isSidenavVisible">
        <div [matTooltip]="isSidenavVisible ? 'Hide menu': 'Show menu'" class="sidenav-toggle"
            (click)="isSidenavVisible = !isSidenavVisible">
            <mat-icon>{{isSidenavVisible ? 'chevron_left': 'chevron_right'}}</mat-icon>
        </div>
        <section class="flex-1 w-full">
            <app-switcher></app-switcher>
            <mat-divider></mat-divider>
            <ul class="no-select menu mt-5">
                <li>
                    <a class="flex justify-start items-center cursor-pointer menu-item" routerLinkActive="active"
                        [routerLink]="['.']" [queryParams]="{ section: 'overview' }">
                        <mat-icon class="me-2" inline>account_balance</mat-icon>
                        <h6 class="text-[0.8rem]">Overview</h6>
                    </a>
                </li>
                <!-- <li>
                    <a class="flex justify-start items-center cursor-pointer menu-item" routerLinkActive="active"
                        [routerLink]="['.']" [queryParams]="{ section: 'settings' }">
                        <mat-icon class="me-2" inline>settings</mat-icon>
                        <h6 class="text-[0.8rem]">Settings</h6>
                    </a>
                </li> -->
            </ul>
        </section>
    </mat-drawer>
    <mat-drawer-content class="flex flex-col flex-nowrap bg-bgDefault">
        <app-header [title]="pageTitle"></app-header>

        @switch (this.status) {
        @case (Status.active) {
        @if (this.mode === 'list') {
        <section class="px-6">
            <p class="text-sm mb-4">
                A comprehensive overview of all your deployment environments
            </p>
            <button (click)="this.mode = 'create'; this.pageTitle = 'Create Environment'"
                class="btn btn-sm btn-primary pl-2 pr-4 flex items-center">
                <mat-icon class="mr-2">add</mat-icon>
                Create Environment
            </button>

            <table mat-table [dataSource]="envDataSource" class="table-auto w-full border-borderColor border mt-5">

                <!-- Environment Name -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="message-sm">{{row.name}}</span>
                    </td>
                </ng-container>

                <!-- Environment Type -->
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="message-sm">{{row.type | uppercase}}</span>
                    </td>
                </ng-container>

                <!-- Environment Location -->
                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef>Location</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="message-sm">{{row.location}}</span>
                    </td>
                </ng-container>

                <!-- Environment Status -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let row">
                        <div class="flex items-center">
                            @if (row.status === 'pending' || row.status === 'purchased') {
                            <mat-icon class="text-yellow-500 mr-2">info</mat-icon>
                            }  @else if (row.status === 'failed') {
                            <mat-icon class="text-btnDestructive mr-2">info</mat-icon>
                            } @else if (row.status === 'active') {
                            <mat-icon class="text-bgSuccess mr-2">check_circle</mat-icon>
                            } @else if (row.status === 'deleted') {
                            <mat-icon class="text-buttonDangerColor mr-2">error</mat-icon>
                            } @else if (row.status === 'activating') {
                            <mat-icon class="text-btnPrimary mr-2">sync</mat-icon>
                            }
                            <span class="message-sm">{{row.status | titlecase}}</span>
                        </div>
                    </td>
                </ng-container>

                <!-- Timestamp -->
                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef>Created On</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="message-sm">{{row.created_at | date:'medium'}}</span>
                    </td>
                </ng-container>

                <!-- Actions -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon class="cursor-pointer text-primary" (click)="viewEnvironment(row.id)"
                            matTooltip="View Environment">edit</mat-icon>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                        No active requests found
                    </td>
                </tr>
            </table>

            <mat-paginator class="p-4 text-base" [length]="envDataSource.data.length" [pageSize]="15"
                aria-label="Select page of requests" #paginator></mat-paginator>
        </section>
        } @else if(this.mode === 'view' && this.activeEnvironment) {
        <!-- Environment Details -->
        <section class="px-6">
            <p class="text-sm mb-4">Information regarding your deployment environment can be found below</p>
            <div class="grid grid-cols-1 gap-4">
                <div class="grid grid-cols-1 divide-y ring-1 ring-borderColor rounded">
                    <div class="grid grid-cols-1 md:grid-cols-5 divide-x">
                        <div class="flex flex-col py-3 px-5">
                            <span class="text-xs mb-2">Name</span>
                            <h6 class="text-lg mb-0">{{activeEnvironment.name}}</h6>
                        </div>
                        @if (this.licenseTier !== 'offline') {
                        <div class="flex flex-col py-3 px-5">
                            <span class="text-xs mb-2">Type</span>
                            <h6 class="text-lg mb-0">{{activeEnvironment.type | uppercase}}</h6>
                        </div>
                        <div class="flex flex-col py-3 px-5">
                            <span class="text-xs mb-2">Location</span>
                            <h6 class="text-lg mb-0">{{activeEnvironment.location}}</h6>
                        </div>
                        }   
                        <div class="flex flex-col items-start py-3 px-5">
                            <span class="text-xs mb-2">Status</span>
                            <p [ngClass]="{'status-pending': activeEnvironment.status === 'pending' || activeEnvironment.status === 'purchased', 'status-active': activeEnvironment.status === 'active', 'status-deleted': activeEnvironment.status === 'deleted' || activeEnvironment.status === 'failed'}"
                                class="badge badge-status mb-0">{{activeEnvironment.status}}</p>
                        </div>
                        <div class="flex flex-col py-3 px-5">
                            <span class="text-xs mb-2">Created On</span>
                            <h6 class="text-base mb-0">{{activeEnvironment.created_at| date:'medium'}}</h6>
                        </div>
                    </div>
                    <div class="flex flex-col py-3 px-5">
                        <span class="text-xs mb-2">Description</span>
                        <h6 class="text-base mb-0">{{activeEnvironment.description}}</h6>
                    </div>
                    <div *ngIf="this.activeEnvironment.status !== 'deleted' && this.activeEnvironment.status !== 'pending'"
                        class="flex flex-col py-3 px-5">
                        <span class="text-xs mb-2">Active Deployments</span>
                        <h6 class="text-base mb-0">{{activeEnvironment.deployments?.length ?? 0}}</h6>
                    </div>
                </div>
                @if(this.activeEnvironment.deleted && this.activeEnvironment.status !== 'deleted') {
                <div class="ring-1 ring-borderColor rounded px-5 py-3 flex items-center">
                    <mat-icon class="mr-5 text-bgWarning">warning</mat-icon>
                    <div class="flex flex-col">
                        <h6 class="text-base mb-2">Scheduled For Deletion</h6>
                        <p class="message-sm">
                            This environment has been scheduled for deleteion and will be removed permanently by <b
                                class="font-medium">{{this.activeEnvironment.end_date | date:'medium'}}</b>
                        </p>
                    </div>
                </div>
                } @else if(this.activeEnvironment.status === 'deleted') {
                <div class="ring-1 ring-borderColor rounded px-5 py-3 flex items-center">
                    <mat-icon class="mr-5 text-btnDestructive">warning</mat-icon>
                    <div class="flex flex-col">
                        <h6 class="text-base mb-2">Environment Deleted</h6>
                        <p class="message-sm">
                            This environment has been deleted permanently and is no longer usable.
                        </p>
                    </div>
                </div>
                }

                @if (this.activeEnvironment.status === 'pending') {
                <div class="ring-1 ring-btnPrimary rounded px-5 py-4">
                    <h6 class="text-base mb-2">Purchase Pending</h6>
                    <p class="message-sm mb-4">This environment is still in the pending status. Please complete your
                        payment by clicking on the button below to activate the environment for use.</p>
                    <button class="btn btn-sm btn-primary px-3" (click)="purchaseEnvironment()">Complete
                        Purchase</button>
                </div>
                } @else if (this.activeEnvironment.status === 'activating') {
                <div class="ring-1 ring-btnPrimary rounded px-5 py-4">
                    <div class="flex items-center mb-2">
                        <mat-spinner [diameter]="15" class="mr-2"></mat-spinner>
                        <h6 class="text-base">Activation In Progress</h6>
                    </div>
                    <p class="message-sm">This environment is now being activated. This process can usually take a
                        while. You can come back to this page in some time to check the status of your environment. Once
                        activated, the environment can be used to deploy applications</p>
                </div>
                } @else if (this.activeEnvironment.status === 'active') {
                <!-- Specifications Section -->
                 @if(this.licenseTier !== 'offline'){
                    <ng-container *ngTemplateOutlet="envSpecsTemplate"></ng-container>
                 }
                <div class="flex flex-col">
                    <h6 class="text-base mb-2">Networking & Access</h6>
                    <p class="text-sm mb-5">
                        Important networking and access management details about the environment are mentioned below
                    </p>
                    <div class="ring-1 ring-borderColor rounded">
                        <div class="flex flex-col py-3 px-5">
                            <span class="text-xs mb-2">IP Address</span>
                            <a class="text-lg mb-0 text-btnPrimary flex items-center"
                                href="http://{{activeEnvironment.public_ip}}">
                                <mat-icon class="text-btnPrimary mr-2">open_in_new</mat-icon>
                                <span>{{activeEnvironment.public_ip}}</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <app-env-iam></app-env-iam>
                </div>
                @if(!this.activeEnvironment.deleted) {
                <mat-divider></mat-divider>
                <div class="flex flex-col my-4">
                    <h6 class="text-base mb-2">Actions</h6>
                    <p class="text-sm mb-5">
                        If you no longer need the environment, then you can delete the environment to reduce your
                        overall billing costs.
                    </p>
                    <div class="flex">
                        <button (click)="deleteEnvironment()"
                            class="btn btn-secondary text-btnDestructive px-3 border border-btnDestructive">Delete
                            Environment</button>
                    </div>
                </div>
                } @else {
                <mat-divider></mat-divider>
                <div class="flex flex-col my-4">
                    <h6 class="text-base mb-2">Actions</h6>
                    <p class="text-sm mb-5">
                        You can cancel the deletion of your environment anytime before it is permanently deleted.
                        Restoring the environment will also resume it's billing.
                    </p>
                    <div class="flex">
                        <button (click)="restoreEnvironment()"
                            class="btn btn-secondary text-btnPrimary px-3 border border-btnPrimary">Restore
                            Environment</button>
                    </div>
                </div>
                }
                } @else if(this.activeEnvironment.status === 'purchased') {
                <div class="ring-1 ring-borderColor py-3 px-4 rounded flex flex-col items-start">
                    <h6 class="text-base mb-1">Environment Purchased</h6>
                    <p class="text-sm mb-3">Your environment purchase was successful, please click on the button below
                        if environment is not created</p>
                        <div class="flex items-center gap-4">
                            <button (click)="activateEnvironment()" class="btn btn-sm btn-primary px-4">Activate Environment</button>
                            <button (click)="contactUs()" type="button" class="btn btn-secondary text-btnPrimary border border-btnPrimary px-3 mr-3">Contact Us</button>
                        </div>
                </div>
                } @else if(this.activeEnvironment.status === 'failed') {
                <div class="ring-1 ring-borderColor py-3 px-4 rounded flex items-center">
                    <mat-icon class="text-btnDestructive ml-6 mr-10">warning</mat-icon>
                    <div class="flex flex-col items-start">
                        <h6 class="text-base mb-1">Environment Creation Failed</h6>
                        <p class="text-sm mb-3">
                            Your environment purchase was successful, but something went wrong while trying to create your environment. Please click on the button below
                            to retry creating the environment.
                        </p>
                        <div class="flex items-center gap-4">
                            <button (click)="activateEnvironment()" class="btn btn-sm btn-primary px-4">Retry Creation</button>
                            <button (click)="contactUs()" type="button" class="btn btn-secondary text-btnPrimary border border-btnPrimary px-3 mr-3">Contact Us</button>
                        </div>
                    </div>
                </div>
                }
                <mat-divider></mat-divider>
                <div class="flex items-center mb-5">
                    <button type="button" (click)="back()"
                        class="btn btn-sm btn-secondary text-btnPrimary border border-borderColor px-4">Back</button>
                </div>
            </div>
        </section>
        } @else if (this.mode === 'create') {
        <section class="px-6">
            @if(this.licenseTier !== 'offline'){
                <form [formGroup]="environmentCreationForm" (ngSubmit)="createEnvironment()">
                    <div class="rounded px-4 py-2 ring-1 ring-borderColor flex items-center mb-5 mt-2">
                        <mat-icon class="text-bgWarning mr-2">error</mat-icon>
                        <p class="text-sm">You are creating a new deployment environment, which can have an impact on your
                            billing.</p>
                    </div>
                    <!-- Environment Locations -->
                    <div class="flex flex-col mb-5">
                        <h6 class="text-base mb-2">Location</h6>
                        <p class="text-sm mb-5">Choose the region in which the environment should be deployed</p>
                        <div class="grid grid-cols-10">
                            <div class="col-span-3">
                                <label for="envLocation">Location</label>
                                <ng-select (change)="this.getEnvSpecs()" formControlName="location" [items]="locations"
                                    bindLabel="regionalDisplayName" bindValue="name"></ng-select>
                            </div>
                        </div>
                    </div>
                    <!-- Environment Type -->
                    <h6 class="text-base mb-2">Environment Type</h6>
                    <p class="text-sm mb-5">Choose the type of environment you wish to create, the cost for an environment
                        depends on
                        the type of environment selected</p>
                    <div class="grid grid-cols-1 gap-4 md:grid-cols-5 mb-5">
                        @for (tier of this.tiers; let index=$index; track index;) {
                        <div (click)="setEnvironmentTier(index)"
                            class="ring-1 ring-borderColor rounded p-4 flex items-center cursor-pointer"
                            [ngClass]="{'ring-btnPrimary bg-bgHover': tier.selected}">
                            <mat-icon *ngIf="tier.selected" class="text-bgSuccess mr-4">check_circle</mat-icon>
                            <div class="flex flex-col">
                                <h6 class="text-base mb-2">{{tier.key | uppercase}}</h6>
                                <p class="text-sm opacity-50">{{tier.label}}</p>
                            </div>
                        </div>
                        }
                    </div>

                    <!-- Resources Section -->
                    @if (this.selected && this.location) {
                    <ng-container *ngTemplateOutlet="envSpecsTemplate"></ng-container>
                    }

                    <div class="flex flex-col mb-5">
                        <h6 class="text-base mb-2">Details</h6>
                        <p class="text-sm mb-5">
                            Fill out the details below to create your environment, mandatory fields are marked with a
                            <span class="text-btnDestructive">*</span>
                            character
                        </p>
                        <div class="grid grid-cols-1 md:grid-cols-10 gap-4">
                            <div class="col-span-3">
                                <label for="envName" class="mb-2">Name<span class="text-btnDestructive">*</span></label>
                                <input formControlName="name" id="envName" type="text" maxlength="255" class="input"
                                    placeholder="Enter the environment name" />
                            </div>
                            <div class="col-span-7">
                                <label for="envDescription" class="mb-2">Description</label>
                                <input formControlName="description" id="envDescription" type="text" maxlength="500"
                                    class="input" placeholder="Describe the environment in brief" />
                            </div>
                        </div>
                    </div>

                    <!-- Pricing -->
                    <div class="flex flex-col mb-5">
                        <h6 class="text-base mb-2">Pricing</h6>
                        <p class="text-sm mb-5">Details regarding the environment pricing are given below, pricing may vary
                            depending on the type and location of the environment</p>
                        <div class="ring-1 ring-borderColor rounded p-4 flex flex-col mb-3">
                            <h6 class="text-base mb-1">Total</h6>
                            <div class="flex items-center">
                                <h4 class="text-2xl font-medium mr-1">{{selected?.price | currency: 'USD':'symbol':'.0-0'}}
                                </h4>
                                <p class="text-base">/ month</p>
                            </div>
                        </div>
                        <div class="mt-3 flex items-center">
                            <input type="checkbox" formControlName="consent" required
                                class="mr-2 w-4 h-4 text-btnPrimary bg-bgDefault border-borderColor rounded-sm cursor-pointer mb-0" />
                            <label class="text-sm">
                                By proceeding, I agree to the <a href="" target="_blank" class="text-btnPrimary">Terms of
                                    Service</a>. I hereby
                                authorize PIES Studio to charge my payment method as per the billing cycle till cancelled.
                            </label>
                        </div>
                    </div>

                    <!-- CTA Buttons -->
                    <div class="flex items-center mb-5">
                        <button [disabled]="!this.environmentCreationForm.valid" type="submit"
                            class="btn btn-sm btn-primary px-4 mr-3">Confirm & Proceed</button>
                        <button type="button" (click)="back()"
                            class="btn btn-sm btn-secondary text-btnPrimary border border-borderColor px-4">Back</button>
                    </div>
                </form>
            }@else{
                <form [formGroup]="environmentCreationFormForOffline" (ngSubmit)="createEnvironmentForOfflineLicense()">
                    
                    <div class="flex flex-col mb-5">
                        <h6 class="text-base mb-2">Details</h6>
                        <p class="text-sm mb-5">
                            Fill out the details below to create your environment, mandatory fields are marked with a
                            <span class="text-btnDestructive">*</span>
                            character
                        </p>
                        <div class="grid grid-cols-1 md:grid-cols-10 gap-4">
                            <div class="col-span-3">
                                <label for="envName" class="mb-2">Name<span class="text-btnDestructive">*</span></label>
                                <input formControlName="name" id="envName" type="text" maxlength="255" class="input"
                                    placeholder="Enter the environment name" />
                            </div>
                            <div class="col-span-7">
                                <label for="envDescription" class="mb-2">Description</label>
                                <input formControlName="description" id="envDescription" type="text" maxlength="500"
                                    class="input" placeholder="Describe the environment in brief" />
                            </div>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-10 gap-4">
                            <div class="col-span-3">
                                <label for="publicIp" class="mb-2">Public IP<span class="text-btnDestructive">*</span></label>
                                <input formControlName="publicIp" id="publicIp" type="text" maxlength="255" class="input"
                                    placeholder="Enter the public IP" />
                            </div>
                            <div class="col-span-3">
                                <label for="username" class="mb-2">Username<span class="text-btnDestructive">*</span></label>
                                <input formControlName="username" id="username" type="text" maxlength="500"
                                    class="input" placeholder="Enter the VM username" />
                            </div>
                            <div class="col-span-4">
                                <label for="sshPort" class="mb-2">SSH Port<span class="text-btnDestructive">*</span></label>
                                <input formControlName="sshPort" id="sshPort" type="text" maxlength="500" [defaultValue]="22"
                                    class="input" placeholder="Enter the ssh port. Default: 22" />
                            </div>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-10 gap-4">
                            <div class="col-span-3">
                                
                                <label for="privateKey" class="mb-2">Private Key<span class="text-btnDestructive">*</span></label>
                                <input id="privateKey" type="file" maxlength="500" (change)="onFileChange($event)"
                                    class="input" placeholder="Select the private key file." />
                                <p class="text-sm mb-2">Key file should not be passphrase protected!</p>
                            </div>
                        </div>
                    </div>                    

                    <!-- CTA Buttons -->
                    <div class="flex items-center mb-5">
                        <button [disabled]="!this.environmentCreationFormForOffline.valid" type="submit"
                            class="btn btn-sm btn-primary px-4 mr-3">Confirm & Proceed</button>
                        <button type="button" (click)="back()"
                            class="btn btn-sm btn-secondary text-btnPrimary border border-borderColor px-4">Back</button>
                    </div>
                </form>
            } 
        </section>
        }
        }

        @case (Status.error) {
        <section class="flex items-center justify-center flex-col w-full h-full px-6">
            <mat-icon class="text-btnDestructive mb-3">error</mat-icon>
            <h6 class="text-base mb-2">Something went wrong while handling your request</h6>
            <p class="text-sm mb-2">An error occured while processing your reuest, we are looking into it. Please
                refresh the page after some time to try again.</p>
        </section>
        }

        @case (Status.loading) {
        <section class="flex items-center justify-center w-full h-full px-6">
            <mat-spinner [diameter]="20" class="mr-2"></mat-spinner>
            <p class="text-sm">Please wait...</p>
        </section>
        }
        }

    </mat-drawer-content>
</mat-drawer-container>

<ng-template #envSpecsTemplate>
    <div class="flex flex-col mb-2">
        <h6 class="text-base mb-2">Sepcifications</h6>
        <p class="text-sm mb-5">Details regarding the resource specifications of the selected environment
            are shown
            below
        </p>
        @switch (this.environmentSpecs.status) {
        @case ('loading') {
        <div class="flex items-center">
            <mat-spinner [diameter]="15" class="mr-2"></mat-spinner>
            <p class="text-sm">Please wait...</p>
        </div>
        }
        @case ('active') {
        <div class="ring-1 ring-borderColor rounded grid grid-cols-1 md:grid-cols-3 divide-x">
            <div class="flex items-center p-4">
                <mat-icon class="mr-4 text-btnPrimary">memory_alt</mat-icon>
                <div class="flex flex-col">
                    <label class="mb-1">Memory</label>
                    <h6 class="text-base">{{this.environmentSpecs.memory}}</h6>
                </div>
            </div>
            <div class="flex items-center p-4">
                <mat-icon class="mr-4 text-btnPrimary">memory</mat-icon>
                <div class="flex flex-col">
                    <label class="mb-1">CPUs</label>
                    <h6 class="text-base">{{this.environmentSpecs.cpu}}</h6>
                </div>
            </div>
            <div class="flex items-center p-4">
                <mat-icon class="mr-4 text-btnPrimary">storage</mat-icon>
                <div class="flex flex-col">
                    <label class="mb-1">Storage</label>
                    <h6 class="text-base">{{this.environmentSpecs.storage}}</h6>
                </div>
            </div>
        </div>
        }
        @case ('error') {
        <div class="ring-1 ring-borderColor rounded flex items-center p-4">
            <mat-icon class="text-btnDestructive mr-2">error</mat-icon>
            <p class="text-sm">Unable to fetch environment specifications. Please try again later.</p>
        </div>
        }
        }
    </div>
</ng-template>