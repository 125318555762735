import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { url } from '../../environments/url';
import { AuthResponse, Register } from '../model/auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  set state(payload: AuthResponse) {
    sessionStorage.setItem('auth', JSON.stringify(payload));
  }

  get state(): AuthResponse {
    return JSON.parse(sessionStorage.getItem('auth') ?? "{}");
  }

  get loggedIn(): boolean {
    const access_token = this.state.access_token;

    if (access_token) {
      return true;
    }

    return false;
  }

  isLoggedIn(): boolean {
    if (sessionStorage.getItem('userInfo')) {
      return true;
    }
    return false;
  }

  register(payload: Register) {
    return this.http.post<string>(
      url.register,
      payload,
    );
  }

  verifyPasswordToken(token: string) {
    return this.http.post(
      url.auth.verifyToken,
      {
        token: token,
      }
    );
  }

  setPassword(token: string, password: string) {
    return this.http.post(
      url.auth.resetPassword,
      {
        token: token,
        password: password,
      }
    );
  }

  forgotPassword(email: string) {
    return this.http.post(
      url.auth.forgotPassword,
      {
        email: email,
      },
    );
  }

  updateDynamicBranding(formdata: FormData) {
    return this.http.post(environment.backend + url.organization.updateBranding,
      formdata,
      {
        responseType: 'text'
      });
  }

  getDynamicBranding(clientId: string) {
    return this.http.get(environment.backend + url.organization.getBranding + '?client_id=' + clientId);
  }

  updateSettings(payload: any) {
    return this.http.post(environment.backend + url.auth.updateSettings, payload, {
      responseType: 'text',
    })
  }

  getToken(code: string, state: string) {
    return this.http.get<AuthResponse>(
      url.token,
      {
        params: {
          code: code,
          state: state,
          client_id: environment.auth.client_id,
        },
      }
    );
  }

  logOut() {
    if (sessionStorage.getItem("authMode") === "ldap") {
    } else {
      sessionStorage.clear();
      this.router.navigate(['']);
    }
  }
}
