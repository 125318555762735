import { CommonModule } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgSelectModule } from "@ng-select/ng-select";
import { Select, Store } from "@ngxs/store";
import { UiSwitchModule } from "ngx-ui-switch";
import { Observable, Subject, takeUntil } from "rxjs";
import { environment } from "../../../../../../environments/environment";
import { AddOnAction } from "../../../../../action/addon.action";
import { Status } from "../../../../../model/enum";
import { User } from "../../../../../model/user.model";
import { AddOnState } from "../../../../../state/add_on.state";
import { OrgState } from "../../../../../state/org.state";

@Component({
    selector: 'app-add-on',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        NgSelectModule,
        MatProgressSpinnerModule,
        UiSwitchModule,
    ],
    templateUrl: './add_on.dialog.html',
    styleUrl: './add_on.dialog.scss'
})
export class AddOnDialogComponent implements OnInit, OnDestroy {

    pricing: {
        tier: string,
        price_monthly: number,
        price_yearly: number,
    }[] = [];
    cycles = [
        { label: 'Annual', value: 'year' },
        { label: 'Monthly', value: 'month' },
    ]

    cycle: string = 'month';
    selectedTier?: {
        tier: string,
        price_month: number,
        price_year: number,
    };

    status: Status = Status.idle;
    userCount: number = 0;

    private destroy$: Subject<boolean> = new Subject<boolean>();

    @Select(AddOnState.getPricing) pricing$!: Observable<any[]>;
    @Select(AddOnState.getStatus) status$!: Observable<Status>;
    @Select(OrgState.getUsers) private users$!: Observable<User[]>;

    Status = Status;

    constructor(
        private store: Store,
        public dialogRef: MatDialogRef<AddOnDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            client_id: string,
            type: string,
            scope: string,
            mode: string,
        },
    ) { }

    ngOnInit(): void {
        this.store.dispatch(new AddOnAction.GetAddOnPricing(this.data.type));

        this.pricing$.pipe(takeUntil(this.destroy$)).subscribe(response => {
            this.pricing = response;
        });

        this.status$.pipe(takeUntil(this.destroy$)).subscribe(response => {
            this.status = response;
        });

        this.users$.pipe(takeUntil(this.destroy$)).subscribe(response => {
            this.userCount = response.length;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    updatePrice(selected: {
        tier: string,
        price_month: number,
        price_year: number,
    }) {
        this.selectedTier = selected;
    }

    proceed() {
        if (this.selectedTier) {

            const host = environment.host;
            const success = `${host}/dashboard?section=license&client_id=${this.data.client_id}&session_id={CHECKOUT_SESSION_ID}&status=success&mode=addon`
            const error = `${host}/dashboard?section=license&client_id=${this.data.client_id}&session_id={CHECKOUT_SESSION_ID}&status=error&mode=addon`

            this.store.dispatch(
                new AddOnAction.PurchaseAddOn(
                    this.data.client_id,
                    this.data.type,
                    this.data.scope,
                    {
                        'units': parseInt(this.selectedTier.tier),
                        'cycle': this.cycle,
                        'success_url': success,
                        'error_url': error,
                    },
                ),
            );
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    confirm() {
        this.dialogRef.close(true)
    }
}