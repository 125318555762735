import { Status } from "../model/enum";

export namespace AddOnAction {
    export class SetStatus {
        static readonly type = "[AddOn] Set Status";
        constructor(public status: Status) { }
    }

    export class GetAddOnPricing {
        static readonly type = "[AddOn] Get Add-On Pricing";
        constructor(public type: string) { }
    }

    export class PurchaseAddOn {
        static readonly type = "[AddOn] Purchase Add-On";
        constructor(public client_id: string, public type: string, public scope: string, public metadata: any) { }
    }

    export class CancelAddOn {
        static readonly type = "[AddOn] Cancel Add-On";
        constructor(public client_id: string, public type: string, public scope: string) { }
    }
}