import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { first, Observable, Subject, takeUntil } from 'rxjs';
import { AddOnAction } from '../../../../action/addon.action';
import { BillingAction } from '../../../../action/billing.action';
import { LicenseAction } from '../../../../action/license.action';
import { OrgAction } from '../../../../action/org.action';
import { AddOn } from '../../../../model/addon.model';
import { Subscription } from '../../../../model/billing/billing.model';
import { Status } from '../../../../model/enum';
import { License } from '../../../../model/license.model';
import { User } from '../../../../model/user.model';
import { AlertService } from '../../../../service/alert.service';
import { BillingState } from '../../../../state/billing.state';
import { LicenseState } from '../../../../state/license.state';
import { OrgState } from '../../../../state/org.state';
import { DialogComponent } from '../../../utils/dialog/dialog.component';
import { BrandingComponent } from "../branding/branding.component";
import { AddOnDialogComponent } from './add_on/add_on.dialog';
import { UpgradeDialogComponent } from './upgrade/upgrade.dialog';
import { UserDialogComponent } from './user/user.dialog';

@Component({
  selector: 'app-iam',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatTabsModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    BrandingComponent
  ],
  templateUrl: './iam.component.html',
  styleUrl: './iam.component.scss'
})
export class IamComponent implements OnInit, OnDestroy {

  private cycle: string = '';
  private units: number = 0;
  private clientId: string = '';
  private isCancelled: boolean = false;
  private licenseTier: string = '';

  private destroy$: Subject<boolean> = new Subject();

  private redirectUri: string = "";

  @Select(OrgState.getUsers) private users$!: Observable<User[]>;
  @Select(BillingState.getSubscription) private subscription$!: Observable<Subscription>;
  @Select(LicenseState.getActiveLicense) private license$!: Observable<License | undefined>;
  @Select(OrgState.getAddOns) private addOns$!: Observable<AddOn[]>;

  displayedColumns = ["name", "email", "phone", "timestamp", "status", "actions"];
  userDataSource: User[] = [];

  msSSOFormGroup = new FormGroup({
    enabled: new FormControl<boolean>(false, Validators.required),
    client_id: new FormControl<string>(''),
    client_secret: new FormControl<string>(''),
  });

  piesAiUsage?: {
    total: number;
    used: number;
    cycle: string;
    subscription: string;
  };

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private alertService: AlertService,
    private route: ActivatedRoute,
  ) { }

  get totalSeats() {
    return this.units;
  }

  get consumedSeats() {
    return this.userDataSource.length;
  }

  get consumedQuota() {
    return Math.round(
      (this.consumedSeats / this.totalSeats) * 100,
    )
  }

  get cancelled(): boolean {
    return this.isCancelled;
  }

  get tier() {
    return this.licenseTier;
  }

  get url() {
    return this.redirectUri;
  }

  get isMsEnabled() {
    return (this.msSSOFormGroup.get('enabled')?.value ?? false) === true;
  }

  ngOnInit(): void {

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(
      params => {
        if (params['session_id']) {

          const success = params['status'] === 'success';

          if (success) {
            this.store.dispatch(new AddOnAction.SetStatus(Status.success));
          } else {
            this.store.dispatch(new AddOnAction.SetStatus(Status.error));
          }

          this.dialog.open(
            AddOnDialogComponent,
            {
              disableClose: true,
              minWidth: '20dvw',
              maxWidth: '30dvw',
              data: {
                mode: 'subscription',
              },
            },
          );
        }
      },
    );

    this.license$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.clientId = response.client_id;
          this.units = response.units;
          this.isCancelled = response.cancelled;
          this.licenseTier = response.tier;

          if (response.sso && response.sso.ms) {
            this.msSSOFormGroup.patchValue({
              client_id: response.sso.ms.client_id,
              client_secret: response.sso.ms.client_secret,
              enabled: response.sso.ms.enabled,
            });

            if (response.sso.ms.enabled === true) {
              this.msSSOFormGroup.get('client_id')?.addValidators(Validators.required);
              this.msSSOFormGroup.get('client_secret')?.addValidators(Validators.required);

              this.msSSOFormGroup.updateValueAndValidity();
            }

            this.redirectUri = response.sso.ms.redirect_url;
          }

          this.store.dispatch(new OrgAction.GetUsers(this.clientId));
          this.store.dispatch(new OrgAction.GetAddOnStatus(this.clientId, "pies_ai", "org"));

          if ((response.tier === 'pro' || response.tier === 'enterprise') && !response.offline) this.store.dispatch(new BillingAction.GetSubscription(this.clientId));
        }
      }
    );

    this.users$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.userDataSource = response;
        }
      },
    );

    this.subscription$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response && response.cycle) this.cycle = response.cycle;
      },
    );

    this.addOns$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      const ai = response.filter(item => item.type === 'pies_ai' && item.scope === 'org');

      if (ai && ai.length > 0) {
        const metadata = ai[0].metadata;

        this.piesAiUsage = {
          cycle: metadata['cycle'],
          total: metadata['total_credits'],
          used: metadata['used_credits'],
          subscription: metadata['subscription'] ?? '',
        };
      }
    });

    // Apply conditional validation on the SSO form group
    this.msSSOFormGroup.get('enabled')?.valueChanges.subscribe((enabled) => {
      const clientIdControl = this.msSSOFormGroup.get('client_id');
      const clientSecretControl = this.msSSOFormGroup.get('client_secret');

      if (enabled) {
        clientIdControl?.setValidators([Validators.required]);
        clientSecretControl?.setValidators([Validators.required]);
      } else {
        clientIdControl?.clearValidators();
        clientSecretControl?.clearValidators();
      }
      // Update the validity state
      clientIdControl?.updateValueAndValidity();
      clientSecretControl?.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addUser() {
    const dialog = this.dialog.open(
      UserDialogComponent,
      {
        minWidth: '30vw',
        data: {
          mode: 'add',
        }
      }
    );
  }

  editUser(index: number) {
    const user = this.userDataSource[index];

    if (user) {
      this.dialog.open(
        UserDialogComponent,
        {
          minWidth: '30vw',
          data: {
            mode: 'edit',
            payload: {
              id: user.id,
              name: user.name,
              is_admin: user.access?.admin ?? false,
              purchase: user.access?.purchase ?? false,
            },
          },
        }
      );
    }
  }

  removeUser(index: number) {
    const user = this.userDataSource[index];
    if (!user) return;

    const dialog = this.dialog.open(
      DialogComponent,
      {
        maxWidth: 650,
        data: {
          title: 'Confirm Deletion',
          message: "You are about to remove the user from your organization. This is will immediately revoke their access to the PIES Studio platform. \n\n Are you sure you wish to continue?",
          actionLabel: 'I understand, continue',
        }
      }
    );

    dialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response === true) {

          if (this.piesAiUsage && this.piesAiUsage.subscription === 'active') {
            const dialog = this.dialog.open(
              DialogComponent,
              {
                maxWidth: 650,
                data: {
                  title: 'Subscription Update',
                  message: "You are about to remove the user from your organization. This is will also update your existing subscription for PIES AI and your subscription amount will be adjusted accordingly. \n\n Are you sure you wish to continue?",
                  actionLabel: 'I understand, continue',
                }
              }
            );

            dialog.afterClosed().pipe(first()).subscribe(response => {
              if (response === true) this.store.dispatch(new OrgAction.RemoveUser(this.clientId, user.id));
            });
          } else {
            this.store.dispatch(new OrgAction.RemoveUser(this.clientId, user.id));
          }
        }
      },
    );
  }

  upgradeSeats() {
    this.dialog.open(
      UpgradeDialogComponent,
      {
        minWidth: '25dvw',
        maxWidth: '30dvw',
        data: {
          client_id: this.clientId,
          cycle: this.cycle,
          units: this.units,
          consumed: this.consumedSeats,
          mode: 'upgrade',
        }
      },
    );
  }

  copy(content: string) {
    if (content === 'redirect') {
      content = this.redirectUri;
    }

    navigator.clipboard.writeText(content).then(_ => {
      this.alertService.success('Copied');
    });
  }

  saveSSOChanges(type: string) {
    switch (type) {
      case 'ms':
        if (this.msSSOFormGroup.valid) {
          const payload = {
            client_id: this.clientId,
            provider: 'ms',
            enabled: this.msSSOFormGroup.get('enabled')?.value ?? false,
            metadata: {
              client_id: this.msSSOFormGroup.get('client_id')?.value ?? '',
              client_secret: this.msSSOFormGroup.get('client_secret')?.value ?? '',
            },
          };

          this.store.dispatch(new LicenseAction.SetSSODetails(payload));
        }

        break;
    }
  }

  purchaseAddOn(type: string) {
    const dialog = this.dialog.open(
      AddOnDialogComponent,
      {
        disableClose: true,
        minWidth: '20dvw',
        maxWidth: '30dvw',
        data: {
          'client_id': this.clientId,
          'type': type,
          'scope': 'org',
          'mode': 'subscription',
        }
      },
    );
  }

  cancelAddOn(type: string) {

    this.store.dispatch(new AddOnAction.SetStatus(Status.idle));

    const dialog = this.dialog.open(
      AddOnDialogComponent,
      {
        disableClose: true,
        minWidth: '25dvw',
        maxWidth: '25dvw',
        data: {
          mode: 'cancel',
        }
      },
    );

    dialog.afterClosed().pipe(first()).subscribe(response => {
      if (response === true) {
        this.store.dispatch(new AddOnAction.CancelAddOn(this.clientId, type, 'org'));
      }
    });
  }
}
