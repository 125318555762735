<div class="p-5">
    @if (this.status === Status.connecting) {
    <div class="flex items-center">
        <mat-spinner color="primary" [diameter]="20" class="mr-3"></mat-spinner>
        <span>Processing...</span>
    </div>
    } @else if (this.status === Status.conflict) {
        <div class="flex items-center mb-4">
            <mat-icon class="text-buttonDangerColor mr-3">error</mat-icon>
            <span class="text-sm flex-1">Something went wrong while processing your request, please try again after some time.</span>
        </div>
        <button type="button" class="btn btn-secondary px-3 w-full" (click)="cancel()">Close</button>
    } @else {
    <h6 class="mb-2">Generate License</h6>
    <p class="text-sm mb-4">Configure the details below to generate a new platform license.</p>
    <form [formGroup]="addLicenseFormGroup" (ngSubmit)="proceed()" class="flex flex-col">
        <div class="mb-3">
            <label for="name" class="block mb-2 text-xs font-medium text-fontPrimary">
                Organization Name
                <span class="text-btnDestructive">*</span>
            </label>
            <input id="name" required formControlName="name" class="input mb-0" placeholder="Enter the organization name" />
        </div>
        <div class="flex mb-3 gap-4">
            <div class="flex-1">
                <label for="validity" class="block mb-2 text-xs font-medium text-fontPrimary">
                    Validity
                    <span class="text-btnDestructive">*</span>
                </label>
                <input id="validity" required formControlName="validity" type="date" class="input mb-0"
                    placeholder="Enter the license validity" />
            </div>
            <div class="flex-1">
                <label for="units" class="block mb-2 text-xs font-medium text-fontPrimary">
                    Number of Users
                    <span class="text-btnDestructive">*</span>
                </label>
                <input id="units" formControlName="units" required type="number" class="input mb-0"
                    placeholder="Enter the number of users for the license" />
            </div>
        </div>
        <div class="mb-3">
            <h6 class="text-base mb-1">Mode</h6>
            <p class="text-sm mb-2">Choose the license mode</p>
            <mat-radio-group formControlName="type" aria-label="Select the type of license to be generated"
                color="primary">
                <mat-radio-button [value]="'offline'" class="mr-3">Offline</mat-radio-button>
                <mat-radio-button class="p-0" [disabled]="true" [value]="'online'">Online</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="mb-3">
            <h6 class="text-base mb-1">Add Ons</h6>
            <p class="text-sm mb-4">Choose the available add ons for the license</p>
            <div class="mb-4 flex flex-col ring-1 ring-borderColor rounded divide-y">
                <div class="flex justify-between items-center">
                    <div class="flex flex-col p-3">
                        <h6 class="text-sm">Applications</h6>
                        <p class="text-sm mb-2">Choose the maximum number of applications that can be created under the license</p>
                        <label for="max_applications">Maximum Applications<span class="text-btnDestructive">*</span></label>
                            <input formControlName="max_applications" id="max_applications" type="number" min="1" required class="input" placeholder="Enter the maximum allowed applications"/>
                    </div>
                </div>
                <div class="flex justify-between items-center">
                    <div class="flex flex-col p-3">
                        <h6 class="text-sm">Code Export</h6>
                        <p class="text-sm mb-2">Code exports are {{this.isExportEnabled ? 'enabled' : 'disabled'}}</p>
                    </div>
                    <mat-slide-toggle (change)="onExportChanged($event)" color="primary" formControlName="export" class="mr-3"></mat-slide-toggle>
                </div>
                <div class="flex flex-col divide-y">
                    <div class="flex justify-between items-center p-3">
                        <div class="flex flex-col">
                            <h6 class="text-sm">Hosting</h6>
                            <p class="text-sm mb-2">Hosting requests are {{this.isHostingEnabled ? 'enabled' : 'disabled'}}</p>
                        </div>
                        <mat-slide-toggle (change)="onHostingChanged($event)" color="primary" formControlName="deployment"></mat-slide-toggle>
                    </div>
                    @if (this.isHostingEnabled) {
                        <div class="p-3">
                            <label for="max_users">Maximum Users Per Hosting<span class="text-btnDestructive">*</span></label>
                            <input formControlName="max_users" id="max_users" type="number" min="1" required class="input" placeholder="Enter the maximum allowed application users per hosting request"/>
                        </div>
                    }
                </div>
                <div class="flex justify-between items-center">
                    <div class="flex flex-col p-3">
                        <h6 class="text-sm">PIES AI</h6>
                        <p class="text-sm mb-2">PIES AI virtual assistant is {{this.isAIEnabled ? 'enabled' : 'disabled'}}</p>
                    </div>
                    <mat-slide-toggle (change)="onAIChanged($event)" color="primary" formControlName="ai" class="mr-3"></mat-slide-toggle>
                </div>
            </div>
        </div>
        <button class="btn btn-primary px-3 mb-2">Proceed</button>
        <button type="button" class="btn btn-secondary px-3" (click)="cancel()">Cancel</button>
    </form>
    }
</div>