import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AdminAction } from '../../../../../action/admin.action';
import { Status } from '../../../../../model/enum';
import { AdminState } from '../../../../../state/admin.state';

@Component({
  selector: 'app-generate',
  standalone: true,
  imports: [
    CommonModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
  templateUrl: './generate.component.html',
  styleUrl: './generate.component.scss'
})
export class GenerateComponent implements OnInit, OnDestroy {

  addLicenseFormGroup = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    validity: new FormControl<string>('', Validators.required),
    units: new FormControl<number>(1, [Validators.required, Validators.min(1)]),
    type: new FormControl<'offline' | 'online'>('offline', Validators.required),
    export: new FormControl<boolean>(true, Validators.required),
    deployment: new FormControl<boolean>(true),
    max_users: new FormControl<number>(100),
    max_applications: new FormControl<number>(100),
    ai: new FormControl<boolean>(true),
  });

  status!: Status;
  Status = Status;

  isExportEnabled: boolean = true;
  isHostingEnabled: boolean = true;
  isAIEnabled: boolean = true;

  @Select(AdminState.getStatus) private status$!: Observable<Status>;

  private destroy: Subject<boolean> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<GenerateComponent>,
    private store: Store,
  ) { }

  onExportChanged(value: MatSlideToggleChange) {
    this.isExportEnabled = value.checked;
  }

  onHostingChanged(value: MatSlideToggleChange) {
    this.isHostingEnabled = value.checked;
  }

  onAIChanged(value: MatSlideToggleChange) {
    this.isAIEnabled = value.checked;
  }

  ngOnInit(): void {
    const today = new Date();
    const date = new Date(today.setFullYear(today.getFullYear() + 1));

    this.addLicenseFormGroup.get('validity')?.setValue(
      date.toISOString().split('T')[0],
    );

    this.status$.pipe(takeUntil(this.destroy)).subscribe(response => {
      this.status = response;

      if (this.status == Status.active) {
        this.cancel();
      }
    });

    this.addLicenseFormGroup.get('deployment')?.valueChanges.pipe(takeUntil(this.destroy)).subscribe(response => {
      if (response === true) {
        this.addLicenseFormGroup.get('max_users')?.addValidators([Validators.required, Validators.min(1)]);
      } else {
        this.addLicenseFormGroup.get('max_users')?.clearValidators();
      }

      this.addLicenseFormGroup.get('max_users')?.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  cancel() {
    this.dialogRef.close(false);
  }

  proceed() {
    if (this.addLicenseFormGroup.valid) {
      const value = this.addLicenseFormGroup.value;
      const payload = {
        name: value.name ?? '',
        validity: new Date(value.validity ?? ''),
        units: value.units!,
        type: value.type ?? 'offline',
        add_ons: {
          export: value.export ?? false,
          deployment: value.deployment ?? false,
          ai: value.ai ?? false,
          apps: true,
        },
        max_users: value.max_users!,
        max_applications: value.max_applications!,
      };

      this.store.dispatch(new AdminAction.GenerateLicense(payload));
    }
  }
}
