<section class="flex-1" *ngIf="!isLoading">
    <p class="text-sm text-fontColor mb-4">
        View and manage the color palettes and logos for dynamic branding
    </p>
    <form #myForm="ngForm">
        <div class="flex flex-row columns-1 gap-16 mb-0">
            <div>
                <h4 class="text-lg text-fontColor font-medium mb-2">Mini Logo</h4>
                <div class="flex items-start mb-3">
                    <div
                        class="me-3 w-[70px] max-w-[70px] max-h-[70px] bg-secondaryFontColorLight overflow-hidden flex justify-center items-center rounded-md h-[70px]">
                        <img *ngIf="iconPreview1 !== undefined" [src]="iconPreview1" class="w-4/5" />
                    </div>
                    <div class="flex flex-col">
                        <div class="text-sm mb-1">
                            Upload JPEG, PNG image. Max size 2 MB
                        </div>
                        <a type="button" class="text-primary font-medium cursor-pointer text-sm outline-none border-0"
                            (click)="fileInput1.click()">Browse</a>
                        <input (change)="onFileSelected($event, 'image1')" accept=".jpg,.png" hidden
                            class="d-none form-control" id="file-upload" type="file" #fileInput1 />
                        <small *ngIf="invalidIconSize" class="text-danger">File size should not be more than 2
                            MB</small>
                    </div>
                </div>
            </div>
            <div>
                <h4 class="text-lg text-fontColor font-medium mb-2">Full Logo</h4>
                <div class="flex items-start mb-3">
                    <div
                        class="me-3 w-[70px] max-w-[70px] h-[70px] max-h-[70px] bg-secondaryFontColorLight overflow-hidden justify-center items-center rounded-md flex">
                        <img *ngIf="iconPreview2 !== undefined" [src]="iconPreview2" class="w-4/5" />
                    </div>
                    <div class="flex flex-col">
                        <div class="text-sm mb-1">
                            Upload JPEG, PNG image. Max size 2 MB
                        </div>
                        <a type="button" class="text-primary font-medium cursor-pointer text-sm outline-none border-0"
                            (click)="fileInput.click()">Browse</a>
                        <input (change)="onFileSelected($event, 'image2')" accept=".jpg,.png" hidden
                            class="d-none form-control" id="file-upload" type="file" #fileInput />
                        <small *ngIf="invalidIconSize" class="text-danger">File size should not be more than 2
                            MB</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col divide-y">
            <section class="flex flex-col py-5">
                <h5 class="mb-2 leading-tight text-lg">Color Swatches</h5>
                <p class="text-sm mb-3">Configure the various color swatches shown below to set the color theme for your
                    PIES
                    Studio portal</p>
                <div class="flex flex-row columns-1 gap-10">
                    <div
                        class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                        <h6 class="mb-2 text-lg">Primary Color</h6>
                        <div class="flex flex-row">
                            <div class="flex flex-col flex-1">
                                <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Light</label>
                                <input class="block p-0 outline-none w-full mb-0 mr-1" name="primaryColorlight"
                                    value="blue" type="color" [(ngModel)]="dynamicBrandingPallet.primaryColor.light" />
                            </div>
                            <div class="flex flex-col flex-1">
                                <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Dark</label>
                                <input class="block p-0 outline-none w-full mb-0" value="lightblack"
                                    name="primaryColorDark" type="color"
                                    [(ngModel)]="dynamicBrandingPallet.primaryColor.dark" />
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                        <h6 class="mb-2 text-lg">Secondary Color</h6>
                        <div class="flex flex-row">
                            <div class="flex flex-col">
                                <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Light</label>
                                <input class="block p-0 outline-none w-full mb-0 mr-1" name="secondaryColorlight"
                                    type="color" [(ngModel)]="dynamicBrandingPallet.secondaryColor.light" />
                            </div>
                            <div class="flex flex-col">
                                <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Dark</label>
                                <input class="block p-0 outline-none mb-0" name="secondaryColorlight" type="color"
                                    [(ngModel)]="dynamicBrandingPallet.secondaryColor.dark" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="flex items-start py-5">
                <div class="flex flex-col">
                    <h6 class="mb-2 leading-tight text-lg">Button Color</h6>
                    <p class="mb-3 text-sm">Sets the background color for the portal buttons</p>
                    <div class="flex flex-row columns-1 gap-10">
                        <div
                            class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                            <h6 class="mb-2">Primary Color</h6>
                            <div class="flex flex-row">
                                <div class="flex flex-col">
                                    <label for="primary_color_dark"
                                        class="text-sm text-fontColor font-medium">Light</label>
                                    <input class="block p-0 outline-none mb-0 mr-1" value="blue"
                                        name="buttonColorprimaryColorlight" type="color" [(ngModel)]="
                        dynamicBrandingPallet.buttonColor.primaryColor.light
                      " />
                                </div>
                                <div class="flex flex-col">
                                    <label for="primary_color_dark"
                                        class="text-sm text-fontColor font-medium">Dark</label>
                                    <input class="block p-0 outline-none mb-0" value="lightblack"
                                        name="buttonColorprimaryColordark" type="color" [(ngModel)]="
                        dynamicBrandingPallet.buttonColor.primaryColor.dark
                      " />
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                            <div class="form-group">
                                <h6 class="mb-2">Secondary Color</h6>
                                <div class="flex flex-row">
                                    <div class="flex flex-col">
                                        <label for="primary_color_dark"
                                            class="text-sm text-fontColor font-medium">Light</label>
                                        <input class="block p-0 outline-none mb-0 mr-1" type="color"
                                            name="buttonColorsecondaryColorlight" [(ngModel)]="
                          dynamicBrandingPallet.buttonColor.secondaryColor.light
                        " />
                                    </div>
                                    <div class="flex flex-col">
                                        <label for="primary_color_dark"
                                            class="text-sm text-fontColor font-medium">Dark</label>
                                        <input class="block p-0 outline-none mb-0" type="color"
                                            name="buttonColorsecondaryColordark" [(ngModel)]="
                          dynamicBrandingPallet.buttonColor.secondaryColor.dark
                        " />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                            <h6 class="mb-2">Destructive Color</h6>
                            <div class="flex flex-row">
                                <div class="flex flex-col">
                                    <label for="primary_color_dark"
                                        class="text-sm text-fontColor font-medium">Light</label>
                                    <input class="block p-0 outline-none mb-0 mr-1" value="blue" type="color"
                                        name="buttonColordestructiveColorlight" [(ngModel)]="
                        dynamicBrandingPallet.buttonColor.destructiveColor.light
                      " />
                                </div>
                                <div class="flex flex-col">
                                    <label for="primary_color_dark"
                                        class="text-sm text-fontColor font-medium">Dark</label>
                                    <input class="block p-0 outline-none mb-0" value="lightblack" type="color"
                                        name="buttonColordestructiveColordark" [(ngModel)]="
                        dynamicBrandingPallet.buttonColor.destructiveColor.dark
                      " />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mx-6"></div>
                <div class="flex flex-col">
                    <h6 class="mb-2 leading-tight text-lg">Button Font Color</h6>
                    <p class="text-sm mb-3">Sets the color for the button labels and icons</p>
                    <div class="flex flex-row columns-1 gap-10">
                        <div
                            class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                            <h6 class="mb-2">Primary Color</h6>
                            <div class="flex flex-row">
                                <div class="flex flex-col">
                                    <label for="primary_color_dark"
                                        class="text-sm text-fontColor font-medium">Light</label>
                                    <input class="block p-0 outline-none mb-0 mr-1" value="blue"
                                        name="buttonFontprimaryColorlight" type="color" [(ngModel)]="
                        dynamicBrandingPallet.buttonFontColor.primaryColor.light
                      " />
                                </div>
                                <div class="flex flex-col">
                                    <label for="primary_color_dark"
                                        class="text-sm text-fontColor font-medium">Dark</label>
                                    <input class="block p-0 outline-none mb-0" value="lightblack"
                                        name="buttonFontprimaryColordark" type="color" [(ngModel)]="
                        dynamicBrandingPallet.buttonFontColor.primaryColor.dark
                      " />
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                            <h6 class="mb-2">Secondary Color</h6>
                            <div class="flex flex-row">
                                <div class="flex flex-col">
                                    <label for="primary_color_dark"
                                        class="text-sm text-fontColor font-medium">Light</label>
                                    <input class="block p-0 outline-none mb-0 mr-1" type="color"
                                        name="buttonFontsecondaryColorlight" [(ngModel)]="
                        dynamicBrandingPallet.buttonFontColor.secondaryColor.light
                      " />
                                </div>
                                <div class="flex flex-col">
                                    <label for="primary_color_dark"
                                        class="text-sm text-fontColor font-medium">Dark</label>
                                    <input class="block p-0 outline-none mb-0" type="color"
                                        name="buttonFontsecondaryColordark" [(ngModel)]="
                        dynamicBrandingPallet.buttonFontColor.secondaryColor.dark
                      " />
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                            <h6 class="mb-2">Destructive Color</h6>
                            <div class="flex flex-row">
                                <div class="flex flex-col">
                                    <label for="primary_color_dark"
                                        class="text-sm text-fontColor font-medium">Light</label>
                                    <input class="block p-0 outline-none mb-0 mr-1" value="blue" type="color"
                                        name="buttonFontdestructiveColorlight" [(ngModel)]="
                        dynamicBrandingPallet.buttonFontColor.destructiveColor
                          .light
                      " />
                                </div>
                                <div class="flex flex-col">
                                    <label for="primary_color_dark"
                                        class="text-sm text-fontColor font-medium">Dark</label>
                                    <input class="block p-0 outline-none mb-0" value="lightblack" type="color"
                                        name="buttonFontdestructiveColordark" [(ngModel)]="
                        dynamicBrandingPallet.buttonFontColor.destructiveColor
                          .dark
                      " />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="flex flex-col py-5">
                <h6 class="mb-2 leading-tight text-lg">Font Color</h6>
                <p class="text-sm mb-3">Sets the primary and secondary text colors across the portal</p>
                <div class="flex flex-row columns-1 gap-10">
                    <div
                        class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                        <h6 class="mb-2">Primary Color</h6>
                        <div class="flex flex-row">
                            <div class="flex flex-col">
                                <label for="primary_color_dark">Light</label>
                                <input class="block p-0 outline-none mb-0 mr-1" value="blue" type="color"
                                    name="textColorprimaryColorlight" [(ngModel)]="
                        dynamicBrandingPallet.textColor.primaryColor.light
                      " />
                            </div>
                            <div class="flex flex-col">
                                <label for="primary_color_dark">Dark</label>
                                <input class="block p-0 outline-none mb-0" value="lightblack"
                                    name="textColorprimaryColordark" type="color" [(ngModel)]="
                        dynamicBrandingPallet.textColor.primaryColor.dark
                      " />
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                        <h6 class="mb-2">Secondary Color</h6>
                        <div class="flex flex-row">
                            <div class="flex flex-col">
                                <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Light</label>
                                <input class="block p-0 outline-none mb-0 mr-1" value="blue"
                                    name="textColorsecondaryColorlight" type="color" [(ngModel)]="
                        dynamicBrandingPallet.textColor.secondaryColor.light
                      " />
                            </div>
                            <div class="flex flex-col">
                                <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Dark</label>
                                <input class="block p-0 outline-none mb-0" value="lightblack"
                                    name="textColorsecondaryColordark" type="color" [(ngModel)]="
                        dynamicBrandingPallet.textColor.secondaryColor.dark
                      " />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </form>
    <button
        class="cursor-pointer min-w-[64px] overflow-visible mt-2 mr-2 mb-6 px-4 bg-primary text-btnFontColor text-sm min-h-9 rounded"
        (click)="updateBranding()">
        <span>Save Changes</span>
    </button>
</section>