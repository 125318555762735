import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { url } from "../../environments/url";
import { Environment, Location } from "../model/envrionment.model";


@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {

    constructor(
        private http: HttpClient,
    ) { }

    getEnvironments(clientId: string) {
        return this.http.get<{ id: string, name: string, status: string, type: string, location: string, created_at: Date }[]>(
            `${environment.backend}${url.environment.all}`,
            {
                params: {
                    'client_id': clientId,
                },
            },
        );
    }

    getEnvironment(clientId: string, id: string) {
        return this.http.get<Environment>(
            `${environment.backend}${url.environment.get}`,
            {
                params: {
                    'client_id': clientId,
                    'id': id,
                },
            },
        );
    }

    createEnvironment(clientId: string, env: { name: string, description: string, location: string, type: string }) {
        return this.http.post<{ url: string }>(
            `${environment.backend}${url.environment.create}`,
            {
                'client_id': clientId,
                'name': env.name,
                'description': env.description,
                'location': env.location,
                'type': env.type,
            },
        );
    }

    createEnvironmentForOfflineLicense(clientId: string, env: { name: string, description: string, public_ip: string, private_key: string|undefined, port: string,environment_user: string}) {
        return this.http.post<{ id: string }>(
            `${environment.backend}${url.environment.createOffline}`,
            {
                'client_id': clientId,
                'name': env.name,
                'description': env.description,
                'public_ip': env.public_ip,
                'private_key': env.private_key,
                'port': env.port,
                'environment_user': env.environment_user,
                'type': 'offline'
            },);
    }

    activateEnvironment(id: string) {
        return this.http.post(
            `${environment.backend}${url.environment.activate}`,
            {
                id: id,
            },
        );
    }

    purchaseEnvironment(clientId: string, id: string) {
        return this.http.post<{ url: string }>(
            `${environment.backend}${url.environment.purchase}`,
            {
                'client_id': clientId,
                'id': id,
            },
        );
    }

    shutdownEnvironment(clientId: string, id: string) {
        return this.http.put<{}>(
            `${environment.backend}${url.environment.shutdown}`,
            {
                'client_id': clientId,
                'id': id,
            },
        );
    }

    restartEnvironment(clientId: string, id: string) {
        return this.http.put<{}>(
            `${environment.backend}${url.environment.restart}`,
            {
                'client_id': clientId,
                'id': id,
            },
        );
    }

    deleteEnvironment(clientId: string, id: string) {
        return this.http.post<{}>(
            `${environment.backend}${url.environment.delete}`,
            {},
            {
                params: {
                    'client_id': clientId,
                    'id': id
                },
            },
        );
    }

    restoreEnvironment(clientId: string, id: string) {
        return this.http.post<{}>(
            `${environment.backend}${url.environment.restore}`,
            {},
            {
                params: {
                    'client_id': clientId,
                    'id': id
                },
            },
        );
    }

    getLocations() {
        return this.http.get<Location[]>(
            `${environment.backend}${url.environment.locations}`,
        );
    }

    getEnvironmentSpecs(type: string, location: string) {
        return this.http.get<{ memory: string; cpu: string; storage: string }>(
            `${environment.backend}${url.environment.specs}`,
            {
                params: {
                    type: type,
                    location: location,
                },
            },
        );
    }

    addEnvironmentAccess(client_id: string, envId: string, userId: string) {
        return this.http.post<{}>(
            `${environment.backend}${url.environment.access.add}`,
            {},
            {
                params: {
                    'client_id': client_id,
                    'env_id': envId,
                    'user_id': userId,
                },
            }
        );
    }

    removeEnvironmentAccess(client_id: string, envId: string, userId: string) {
        return this.http.post<{}>(
            `${environment.backend}${url.environment.access.remove}`,
            {},
            {
                params: {
                    'client_id': client_id,
                    'env_id': envId,
                    'user_id': userId,
                },
            }
        );
    }
}