import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { catchError, map, tap } from "rxjs";
import { OrgAction } from "../action/org.action";
import { Organization } from "../model/organization.model";
import { AlertService } from "../service/alert.service";
import { OrgService } from "../service/org.service";

@State<Organization>({
    name: 'org',
    defaults: {
        id: '',
        name: '',
        admin_email: '',
        admin_name: '',
        cloud_platform: '',
        status: '',
        addOns: [],
    }
})
@Injectable()
export class OrgState {

    constructor(
        private orgService: OrgService,
        private alertService: AlertService,
    ) { }

    @Selector()
    static getId(state: Organization) {
        return state.id;
    }

    @Selector()
    static getName(state: Organization) {
        return state.name;
    }

    @Selector()
    static getDomain(state: Organization) {
        return state.domain;
    }

    @Selector()
    static getUsers(state: Organization) {
        return state.users ?? [];
    }

    @Selector()
    static getAddOns(state: Organization) {
        return state.addOns ?? [];
    }

    @Action(OrgAction.GetOrganization)
    fetchOrganization(ctx: StateContext<Organization>, action: OrgAction.GetOrganization) {
        return this.orgService.getOrganization(action.id).pipe(
            tap(response => {
                ctx.setState(response);
            }),
        );
    }

    @Action(OrgAction.GetUsers)
    getUsersByOrganization(ctx: StateContext<Organization>, action: OrgAction.GetUsers) {
        return this.orgService.getUsersByOrg(action.clientId).pipe(
            map(response => {
                for (let user of response) {
                    user.status = 'pending';

                    if (user.is_deleted) {
                        user.status = 'deleted';
                    } else if (user.is_active) {
                        user.status = 'active';
                    }
                }

                return response;
            }),
            tap(response => {
                ctx.patchState({
                    users: response,
                });
            }),
        );
    }

    @Action(OrgAction.AddUser)
    addUserToOrganization(ctx: StateContext<Organization>, action: OrgAction.AddUser) {
        return this.orgService.addUserToOrg(action.client_id, action.paylaod.id, action.paylaod.role, action.paylaod.purchase, action.paylaod.mode, action.paylaod.invitees).pipe(
            tap(_ => {
                this.alertService.success('Success');

                ctx.dispatch(new OrgAction.GetUsers(action.client_id));
            }),
            catchError(error => {
                this.alertService.error('Unable to add user to organization, please try again later');
                throw error;
            }),
        );
    }

    @Action(OrgAction.UpdateUser)
    updateUserForOrganization(ctx: StateContext<Organization>, action: OrgAction.UpdateUser) {
        return this.orgService.updateUserForOrg(action.client_id, action.paylaod.id, action.paylaod.role, action.paylaod.purchase).pipe(
            tap(_ => {
                this.alertService.success('Success');

                ctx.dispatch(new OrgAction.GetUsers(action.client_id));
            }),
            catchError(error => {
                this.alertService.error('Unable to update user for organization, please try again later');
                throw error;
            }),
        );
    }

    @Action(OrgAction.RemoveUser)
    removeUserFromOrganization(ctx: StateContext<Organization>, action: OrgAction.RemoveUser) {
        return this.orgService.removeUserFromOrg(action.client_id, action.user_id).pipe(
            tap(_ => {
                this.alertService.success('Success');

                ctx.dispatch(new OrgAction.GetUsers(action.client_id));
            }),
            catchError(error => {
                this.alertService.error('Unable to delete user from organization, please try again later');
                throw error;
            }),
        )
    }

    @Action(OrgAction.GetAddOnStatus)
    getAddOnStatus(ctx: StateContext<Organization>, action: OrgAction.GetAddOnStatus) {
        return this.orgService.getAddOnStatus(action.client_id, action.type, action.scope).pipe(
            tap(response => {
                if (response) {
                    ctx.patchState({
                        addOns: response,
                    });
                }
            }),
        );
    }
}