import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { catchError, tap } from "rxjs";
import { AddOnAction } from "../action/addon.action";
import { OrgAction } from "../action/org.action";
import { AddOnStateModel } from "../model/addon.model";
import { Status } from "../model/enum";
import { AlertService } from "../service/alert.service";
import { OrgService } from "../service/org.service";

@State<AddOnStateModel>({
    name: 'add_on',
    defaults: {
        status: Status.idle,
        pricing: [],
    },
})
@Injectable()
export class AddOnState {

    constructor(
        private orgService: OrgService,
        private alertService: AlertService,
    ) { }

    @Selector()
    static getStatus(state: AddOnStateModel) {
        return state.status;
    }

    @Selector()
    static getPricing(state: AddOnStateModel) {
        return state.pricing;
    }

    @Action(AddOnAction.SetStatus)
    setStatus(ctx: StateContext<AddOnStateModel>, action: AddOnAction.SetStatus) {
        ctx.patchState({
            status: action.status,
        });
    }

    @Action(AddOnAction.GetAddOnPricing)
    getAddOnPricing(ctx: StateContext<AddOnStateModel>, action: AddOnAction.GetAddOnPricing) {
        switch (action.type) {
            case "pies_ai":
                return this.orgService.getPiesAIPricing(action.type).pipe(
                    tap(response => {
                        ctx.patchState({
                            pricing: response,
                            status: Status.active,
                        })
                    }),
                    catchError(error => {
                        ctx.patchState({
                            status: Status.error,
                        });

                        throw error;
                    }),
                );
            default:
                return;
        }
    }

    @Action(AddOnAction.PurchaseAddOn)
    purchaseAddOn(ctx: StateContext<AddOnStateModel>, action: AddOnAction.PurchaseAddOn) {

        ctx.patchState({
            status: Status.loading,
        });

        return this.orgService.purchaseAddOn(action.client_id, action.type, action.scope, action.metadata).pipe(
            tap(response => {
                window.location.href = response.url;
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                });

                this.alertService.error("Something went wrong, please try again later.");

                throw error;
            }),
        );
    }


    @Action(AddOnAction.CancelAddOn)
    cancelAddOn(ctx: StateContext<AddOnStateModel>, action: AddOnAction.CancelAddOn) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.orgService.cancelAddOn(action.client_id, action.type, action.scope).pipe(
            tap(_ => {
                ctx.patchState({
                    status: Status.cancelled,
                });

                ctx.dispatch(new OrgAction.GetAddOnStatus(action.client_id, "pies_ai", "org"));
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                });

                this.alertService.error("Something went wrong, please try again later.");

                throw error;
            }),
        );
    }
}