import { CommonModule } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { NgSelectModule } from "@ng-select/ng-select";
import { Select, Store } from "@ngxs/store";
import { first, Observable, Subject, takeUntil } from "rxjs";
import { OrgAction } from "../../../../../action/org.action";
import { AddOn } from "../../../../../model/addon.model";
import { AlertService } from "../../../../../service/alert.service";
import { UserService } from "../../../../../service/user.service";
import { LicenseState } from "../../../../../state/license.state";
import { OrgState } from "../../../../../state/org.state";
import { DialogComponent } from "../../../../utils/dialog/dialog.component";

@Component({
    selector: 'app-create-dialog',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgSelectModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatIconModule,
    ],
    templateUrl: './user.dialog.html',
    styleUrl: './user.dialog.scss'
})
export class UserDialogComponent implements OnInit, OnDestroy {

    private destroy$: Subject<boolean> = new Subject();

    roles: { id: string; name: string, is_admin: boolean }[] = [];

    addUserFormGroup = new FormGroup({
        id: new FormControl<string>('', Validators.required),
        role: new FormControl<string>('', [Validators.required]),
        purchase: new FormControl<boolean>(false, [Validators.required]),
    });

    showResults: boolean = false;

    searchMode: 'view' | 'invite' = 'invite';
    selectedUser: string = '';

    isLoading: boolean = false;

    invitedUsers: string[] = [];

    piesAiUsage?: {
        total: number;
        used: number;
        cycle: string;
        subscription: string;
    };

    @Select(OrgState.getAddOns) private addOns$!: Observable<AddOn[]>;

    constructor(
        public dialogRef: MatDialogRef<UserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            mode: 'add' | 'edit';
            payload: {
                id: string;
                name: string;
                is_admin: boolean;
                purchase: boolean;
            };
        },
        private userService: UserService,
        private store: Store,
        private renderer: Renderer2,
        private alertService: AlertService,
        private dialog: MatDialog,
    ) { }

    get isNonAdmin() {
        const role = this.addUserFormGroup.get('role')?.value;

        return !(this.roles.find(r => r.id === role)?.is_admin);
    }

    ngOnInit(): void {
        this.userService.getRoles().pipe(takeUntil(this.destroy$)).subscribe(
            response => {
                if (response) {
                    this.roles = response;

                    if (this.data.mode === 'edit') {
                        const is_admin = this.data.payload.is_admin;

                        const role = this.roles.find(r => r.is_admin === is_admin);

                        this.addUserFormGroup.patchValue({
                            purchase: this.data.payload.purchase,
                            role: role?.id,
                        });

                        this.selectUser(this.data.payload.id, this.data.payload.name);
                    } else {
                        this.addUserFormGroup.get('role')?.setValue(this.roles[0].id);
                    }
                }
            },
        )

        this.addOns$.pipe(takeUntil(this.destroy$)).subscribe(response => {
            const ai = response.filter(item => item.type === 'pies_ai' && item.scope === 'org');

            if (ai && ai.length > 0) {
                const metadata = ai[0].metadata;

                this.piesAiUsage = {
                    cycle: metadata['cycle'],
                    total: metadata['total_credits'],
                    used: metadata['used_credits'],
                    subscription: metadata['subscription'] ?? '',
                };
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    submit() {
        const payload: {
            id: string,
            role: string,
            purchase: boolean,
            mode: string,
            invitees: string[],
        } = {
            id: this.addUserFormGroup.get('id')?.value ?? '',
            role: this.addUserFormGroup.get('role')?.value ?? '',
            purchase: this.addUserFormGroup.get('purchase')?.value ?? false,
            mode: this.searchMode,
            invitees: [],
        };

        if (this.searchMode === 'invite') {
            payload.invitees = this.invitedUsers;
        }

        if (this.addUserFormGroup.valid) {
            const clientId = this.store.selectSnapshot(LicenseState.getActiveLicense)?.client_id ?? '';

            this.addUserFormGroup.reset();
            this.isLoading = true;

            if (this.data.mode === 'edit') {
                this.store.dispatch(new OrgAction.UpdateUser(clientId, payload)).pipe(takeUntil(this.destroy$)).subscribe(
                    _ => {
                        this.dialogRef.close(true);
                    }
                );
            } else {

                if (this.piesAiUsage && this.piesAiUsage.subscription === 'active') {
                    const dialog = this.dialog.open(
                        DialogComponent,
                        {
                            maxWidth: 650,
                            data: {
                                title: 'Subscription Update',
                                message: "You are about to add a user to your organization. This is will also update your existing subscription for PIES AI and your subscription amount will be adjusted accordingly. \n\n Are you sure you wish to continue?",
                                actionLabel: 'I understand, continue',
                            }
                        }
                    );

                    dialog.afterClosed().pipe(first()).subscribe(response => {
                        if (response === true) {
                            this.store.dispatch(new OrgAction.AddUser(clientId, payload)).pipe(takeUntil(this.destroy$)).subscribe(
                                _ => {
                                    this.dialogRef.close(true);
                                }
                            );
                        }
                    });
                } else {
                    this.store.dispatch(new OrgAction.AddUser(clientId, payload)).pipe(takeUntil(this.destroy$)).subscribe(
                        _ => {
                            this.dialogRef.close(true);
                        }
                    );
                }
            }
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    searchUsers(event: Event) {
        this.selectedUser = '';
        this.showResults = true;
    }

    selectUser(id: string, name: string) {
        this.showResults = false;
        this.searchMode = 'view';

        this.selectedUser = name;
        this.addUserFormGroup.get('id')?.setValue(id);
    }

    inviteUser(target: HTMLInputElement) {
        if (this.isValidEmail(target.value)) {
            this.invitedUsers.push(target.value);
            this.showResults = false;
            this.renderer.setProperty(target, 'value', '');
            this.searchMode = 'invite';

            this.addUserFormGroup.get('id')?.setValue('_');
        } else {
            this.alertService.error("Provided string is not a valid email address. Please try again.");
        }
    }

    removeUser(index: number) {
        this.invitedUsers.splice(index);
    }

    /**
     * Validates if the input string is a valid email address.
     * 
     * @param email - The input string to be validated.
     * @returns A boolean indicating whether the input is a valid email address.
     */
    isValidEmail(email: string): boolean {
        // Regular expression for validating an email address
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
}
