import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { UiSwitchModule } from 'ngx-ui-switch';
import { Observable, Subject, takeUntil } from 'rxjs';
import { BillingAction } from '../../../../action/billing.action';
import { LicenseAction } from '../../../../action/license.action';
import { OrgAction } from '../../../../action/org.action';
import { Subscription } from '../../../../model/billing/billing.model';
import { Status } from '../../../../model/enum';
import { License } from '../../../../model/license.model';
import { User } from '../../../../model/user.model';
import { AlertService } from '../../../../service/alert.service';
import { LicenseService } from '../../../../service/license.service';
import { AdminState } from '../../../../state/admin.state';
import { BillingState } from '../../../../state/billing.state';
import { LicenseState } from '../../../../state/license.state';
import { OrgState } from '../../../../state/org.state';
import { DialogComponent, RequestDialogComponent } from '../../../utils/dialog/dialog.component';
import { PricingComponent } from '../../../utils/dialog/pricing/pricing.component';
import { MaterialModule } from '../../../utils/material.module';
import { PaymentDialogComponent } from '../../billing/dialog-payment/payment.dialog';
import { CycleComponent as CycleDialogComponent } from '../iam/cycle/cycle.component';
import { IamComponent } from '../iam/iam.component';
import { UpgradeDialogComponent } from '../iam/upgrade/upgrade.dialog';

@Component({
  selector: 'app-license',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    UiSwitchModule,
    PricingComponent,
    IamComponent,
  ],
  templateUrl: './license.component.html',
  styleUrl: './license.component.scss'
})
export class LicenseComponent implements OnInit, OnDestroy {

  @Input() isAdmin: boolean = false;
  @Input() condensed: boolean = false;

  @Output() back: EventEmitter<boolean> = new EventEmitter();
  @Output() showHeader: EventEmitter<boolean> = new EventEmitter();

  @Select(OrgState.getUsers) private users$!: Observable<User[]>;
  @Select(LicenseState.getAllLicenses) private licenses$!: Observable<License[]>;
  @Select(LicenseState.getActiveLicense) private licenseState$!: Observable<License>;
  @Select(AdminState.getActiveLicense) private adminLicenseState$!: Observable<License>;

  @Select(BillingState.getSubscription) private subscription$!: Observable<Subscription | undefined>;

  mode: 'list' | 'view' | 'create' | 'upgrade' = 'list';
  licenses: License[] = [];

  private destroy: Subject<boolean> = new Subject();

  private licenseEntity: string = "";
  private licenseName: string = '';
  private licenseStatus: string = '';
  private licenseTier: string = '';
  private licenseValidity?: Date;
  private licenseClientId: string = '';
  private licenseClientSecret: string = '';
  private licenseOrganization: string = '';
  private licenseSeats: number = 0;
  private licenseCancelled: boolean = false;
  private licenseOffline: boolean = false;

  private redirectURI: string = "";

  private consumedSeats: number = Infinity;

  isLoading: boolean = true;

  cycle: string = 'year';

  Status = Status;

  constructor(
    private store: Store,
    private alertService: AlertService,
    private licenseService: LicenseService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  get org() {
    return this.licenseOrganization;
  }

  get name(): string {
    return this.licenseName;
  }

  get status() {
    return this.licenseStatus;
  }

  get tier() {
    return this.licenseTier;
  }

  get validity() {
    return this.licenseValidity;
  }

  get clientId() {
    return this.licenseClientId;
  }

  get clientSecret() {
    return this.licenseClientSecret;
  }

  get redirectUri() {
    return this.redirectURI;
  }

  get isCancelled(): boolean {
    return this.licenseCancelled;
  }

  get offline(): boolean {
    return this.licenseOffline;
  }

  ngOnInit(): void {

    this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe(params => {
      if (params['session_id'] && params['mode'] !== 'addon') {

        const clientID = params['client_id'];

        const dialogRef = this.dialog.open(
          PaymentDialogComponent,
          {
            minWidth: '40vw',
            maxWidth: '40vw',
            data: {
              mode: 'check',
              clientId: clientID,
              custom: false,
            },
            disableClose: true,
            position: {
              top: '10vh',
            },
          }
        );

        dialogRef.afterClosed().subscribe((response: { checkout: boolean }) => {
          if (response && response.checkout) {
            this.store.dispatch(new LicenseAction.GetLicenses());
          }
        });
      } else if (params['client_id']) {
        this.viewLicense(params['client_id']);
      }
    });

    this.licenses$.pipe(takeUntil(this.destroy)).subscribe(
      response => {
        this.licenses = response;

        if (this.licenses?.length === 1 && this.licenses[0].status === 'pending') {
          this.viewLicense(this.licenses[0].client_id);
        }
      },
    );

    let activeLicense$ = this.licenseState$;
    if (this.isAdmin) {
      activeLicense$ = this.adminLicenseState$;
    }

    activeLicense$.pipe(takeUntil(this.destroy)).subscribe(
      response => {
        if (response) {
          console.log(response);
          this.licenseName = response.name;
          this.licenseTier = response.tier;
          this.licenseStatus = response.status;
          this.licenseClientId = response.client_id;
          this.licenseValidity = response.end_date;
          this.licenseEntity = response.entity;
          this.licenseClientSecret = response.clientSecret ?? "";
          this.redirectURI = response.redirect;
          this.licenseOrganization = this.licenseTier === 'creator' ? 'PIES Studio Creators' : response.organization;
          this.licenseSeats = response.units;
          this.licenseCancelled = response.cancelled;
          this.licenseOffline = response.offline;

          if (this.licenseStatus === 'pending') {
            this.getPlans();
            this.showHeader.emit(false);
          } else {
            this.showHeader.emit(true);
          }

          if (this.licenseTier == "pro" || this.licenseTier === 'enterprise') {
            this.store.dispatch(new OrgAction.GetOrganization(this.licenseEntity));

            if (this.licenseStatus !== 'pending') this.store.dispatch(new BillingAction.GetSubscription(this.clientId));
          }

          this.mode = 'view';
        } else {
          this.mode = 'list';
        }
      },
    );

    this.subscription$.pipe(takeUntil(this.destroy)).subscribe(response => {
      if (response) this.cycle = response.cycle;
    });

    this.users$.pipe(takeUntil(this.destroy)).subscribe(response => {
      this.consumedSeats = response.length;
    });
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  copy(content: string) {
    navigator.clipboard.writeText(content).then(_ => {
      this.alertService.success('Copied');
    });
  }

  getPlans() {
    this.store.dispatch(new BillingAction.GetPlans(this.cycle));
  }

  regenrateClientSecret() {
    const dialog = this.dialog.open(DialogComponent, {
      maxWidth: 650,
      minWidth: 400,
      data: {
        title: 'Confirm Regeneration',
        message: "You are about to regenerate the Client Secret, after which you will have to re-configure the secret on your PIES Studio installation. \n\n Are you sure you wish to continue? This action cannot be undone.",
        actionLabel: 'I understand, continue',
      }
    });

    dialog.afterClosed().subscribe(response => {
      if (response === true) {
        this.store.dispatch(new LicenseAction.GenerateSecret(this.clientId));
      }
    });
  }

  createRequest(type: string) {
    this.dialog.open(RequestDialogComponent, {
      minWidth: 450,
      maxWidth: 650,
      data: {
        mode: 'create',
        type: type,
        id: '',
      },
    });
  }

  downloadDockerCompose() {
    this.licenseService.downloadDockerCompose(this.clientId).subscribe({
      next: (resp) => {
        const objUrl = window.URL.createObjectURL(resp);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = objUrl;
        a.download = "docker-compose.yaml";
        a.click();
        window.URL.revokeObjectURL(objUrl);
        a.remove();
      }, error: (err) => {
        this.alertService.error('Something went wrong. Please try again.')
      }
    })
  }

  setRedirectUri(event: any) {
    this.redirectURI = event.target.value;
  }

  updateRedirectUri() {
    this.store.dispatch(new LicenseAction.UpdateRedirect(this.clientId, this.redirectURI))
  }

  viewLicense(clientId: string) {
    this.store.dispatch(new LicenseAction.GetLicenseDetails(clientId));
  }

  backToLicenses() {
    this.store.dispatch(new LicenseAction.ResetActiveLicenseIndex());
    this.mode = 'list';
    this.showHeader.emit(true);
  }

  newLicense() {
    this.getPlans();
    this.showHeader.emit(false);
    this.mode = 'create';
  }

  modifySubscription() {
    this.dialog.open(
      UpgradeDialogComponent,
      {
        minWidth: '30dvw',
        maxWidth: '35dvw',
        data: {
          client_id: this.clientId,
          cycle: this.cycle,
          units: this.licenseSeats,
          consumed: this.consumedSeats,
          mode: 'any',
        }
      },
    );
  }

  switchBillingCycle() {
    this.dialog.open(
      CycleDialogComponent,
      {
        minWidth: '25dvw',
        maxWidth: '30dvw',
        data: {
          client_id: this.clientId,
          cycle: this.cycle,
        }
      },
    );
  }

  cancelSubscription() {
    this.router.navigate(
      ['billing'],
      {
        queryParams: {
          'section': 'settings',
        },
      }
    );
  }

  restoreSubscription() {
    this.router.navigate(
      ['billing'],
      {
        queryParams: {
          'section': 'settings',
        },
      }
    );
  }

  upgradeToProLicense() {
    this.mode = 'upgrade';
  }
}
