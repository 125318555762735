import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { AlertService } from '../../../../service/alert.service';
import { AuthService } from '../../../../service/auth.service';
import { LicenseState } from '../../../../state/license.state';

@Component({
  selector: 'app-branding',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './branding.component.html',
  styleUrl: './branding.component.scss'
})
export class BrandingComponent implements OnInit, OnDestroy {

  private destroy$: Subject<boolean> = new Subject();

  invalidIconSize = false;

  isLoading: boolean = true;

  image1!: File;
  image2!: File;
  iconPreview1: any;
  iconPreview2: any;

  dynamicBrandingPallet = {
    primaryColor: {
      dark: "#FFFFFF",
      light: "#FFFFFF",
    },
    secondaryColor: {
      dark: "#FFFFFF",
      light: "#FFFFFF",
    },
    buttonColor: {
      primaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      secondaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      destructiveColor: {
        dark: "#000000",
        light: "#000000",
      },
    },
    buttonFontColor: {
      primaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      secondaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      destructiveColor: {
        dark: "#000000",
        light: "#000000",
      },
    },
    textColor: {
      primaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      secondaryColor: {
        dark: "#003400",
        light: "#000450",
      },
    }
  }

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.getBranding();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getBranding() {
    this.isLoading = true;

    const clientId = this.store.selectSnapshot(LicenseState.getActiveLicense)?.client_id ?? '';

    this.authService.getDynamicBranding(clientId).pipe(takeUntil(this.destroy$)).subscribe((branding: any) => {

      this.dynamicBrandingPallet.primaryColor = branding["primary_color"]
      this.dynamicBrandingPallet.secondaryColor = branding["secondary_color"]
      this.dynamicBrandingPallet.buttonColor = branding["button_color"]
      this.dynamicBrandingPallet.buttonFontColor = branding["button_font_color"]

      this.dynamicBrandingPallet.textColor = branding["font_color"]

      let objectURL = 'data:image/png;base64,' + branding["icon_bytes"];
      this.iconPreview1 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      let objectURL2 = 'data:image/png;base64,' + branding["image_bytes"];
      this.iconPreview2 = this.sanitizer.bypassSecurityTrustUrl(objectURL2);
      this.patchFileInput(objectURL, 2)
      this.patchFileInput(objectURL2, 1)
      this.isLoading = false;

    }, (err: any) => {
      this.isLoading = false;
    })
  }

  patchFileInput(imageData: any, val: number) {
    const blob = this.dataURItoBlob(imageData);
    if (val == 1) {
      const file = new File([blob], 'image2.jpeg', { type: 'image/jpeg' });
      this.image2 = file;
    } else {
      const file = new File([blob], 'image1.png', { type: 'image/png' });
      this.image1 = file;
    }
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  }

  updateBranding() {

    const formData = new FormData();

    const orgId = this.store.selectSnapshot(LicenseState.getActiveLicense)?.entity;

    formData.append("organization_id", orgId ?? '');
    formData.append("primary_color", JSON.stringify(this.dynamicBrandingPallet.primaryColor));
    formData.append("secondary_color", JSON.stringify(this.dynamicBrandingPallet.secondaryColor));
    formData.append("button_color", JSON.stringify(this.dynamicBrandingPallet.buttonColor));
    formData.append("button_font_color", JSON.stringify(this.dynamicBrandingPallet.buttonFontColor));

    formData.append("font_color", JSON.stringify(this.dynamicBrandingPallet.textColor));
    formData.append("iconBytes", this.image1, this.image1.name);
    formData.append("imageBytes", this.image2, this.image2.name);

    this.authService.updateDynamicBranding(formData).subscribe(
      (_: any) => {
        this.alertService.success('Saved');
      },
      (error: any) => {
        this.alertService.error('Something went wrong. Please try again.');
      }
    );
  }

  onFileSelected(event: any, image: any) {
    const file: File = event.target.files[0];
    image = file;
    let fileSize = 2000000;
    if (image.size < fileSize) {
      const reader = new FileReader();
      reader.readAsDataURL(image)
      reader.onload = (readerEvent) => {
        image == "image1" ? this.iconPreview1 = reader.result : this.iconPreview2 = reader.result
      };

    } else {
      this.invalidIconSize = true;
    }
  }
}
