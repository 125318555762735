@if (planStatus === Status.error) {
<div class="flex items-center px-6 py-4 ring-1 ring-borderColor rounded w-full mt-5">
    <mat-icon class="text-btnDestructive mr-2">error</mat-icon>
    <span class="text-sm">Something went wrong while fetching the billing plans, please click </span>
    <button type="button" class="btn btn-secondary text-btnPrimary px-1" (click)="getPlans()">here</button>
    <span class="text-sm">to try again.</span>
</div>
} @else if(planStatus === Status.loading) {
<div class="flex items-center px-6 py-4">
    <mat-spinner [diameter]="15"></mat-spinner>
</div>
} @else {

<div class="flex items-center justify-center p-2 w-full my-2">
    <p class="text-base font-medium" [ngClass]="{'text-bgDefault': public}">Billed Monthly</p>
    <ui-switch [checked]="true" class="mx-2" (valueChange)="changeCycle($event)"></ui-switch>
    <p class="text-base font-medium" [ngClass]="{'text-bgDefault': public}">Billed Yearly</p>
</div>
<div class="grid grid-cols-1 gap-6 mb-5" [ngClass]="{'md:grid-cols-3': showCreator, 'md:grid-cols-2': !showCreator}">
    @for (plan of plans; track $index) {
    @if (plan.name !== 'community') {
    <div class="flex flex-col grow pricing ring-1 ring-btnPrimary rounded bg-bgDefault">
        <div class="flex flex-col px-6 pt-6 rounded-t pricing-header">
            <div class="flex align-center justify-between mb-4">
                <h5 class="text-xl font-medium me-3 mb-0">{{plan.name | titlecase }}</h5>
                @if (plan.highlight) {
                <p class="badge mb-0">Most Popular</p>
                }
            </div>
            <div class="">
                <pre class="text-base mb-5 tagline">{{plan.description}}</pre>
            </div>
            @if (plan.name === 'community' || plan.name === 'creator') {
            <h6 class="text-base mb-2">Free forever</h6>
            } @else if(plan.name === 'pro') {
            <div class="grid grid-cols-2 gap-x-8">
                <div class="flex flex-col">
                    <h6 class="text-base mb-2">Team Size</h6>
                    <ng-select [clearable]="false" [(ngModel)]="this.seats"
                        (change)="updatePrice($event, plan.pricing)">
                        @for (pricing of plan.pricing; track pricing.max_seats; let index = $index;) {
                        @if (pricing.max_seats === null) {
                        <ng-option [value]="0">{{plan.pricing[index - 1].max_seats}}+ Seats</ng-option>
                        } @else {
                        <ng-option [value]="pricing.max_seats">{{pricing.max_seats}} {{pricing.max_seats === 1 ? 'Seat'
                            : 'Seats'}}</ng-option>
                        }
                        }
                    </ng-select>
                </div>
                <div class="flex flex-col">
                    <h6 class="text-base mb-2">Starts from</h6>
                    <div class="flex items-start mb-4">
                        <h1 class="text-4xl mr-2">{{price | currency: plan.currency:'symbol':'.0-0'}}</h1>
                        <p class="text-base">seat / month</p>
                    </div>
                </div>
            </div>
            }
            @if (plan.name === 'community' || plan.name === 'creator') {
            <div class="flex items-center">
                <h1 class="text-4xl mb-4 mr-2">{{0 | currency: plan.currency:'symbol':'.0-0'}}</h1>
                <p class="text-base mb-0 opacity-50">No credit card required</p>
            </div>
            }
            <div class="flex flex-col flex-grow"
                [ngClass]="{'justify-between': plan.name === 'pro', 'justify-end': plan.name !== 'pro'}">
                <mat-divider *ngIf="plan.name === 'pro'"></mat-divider>
                @if(plan.name === 'pro') {
                <div class="flex items-center my-1">
                    <p *ngIf="trialDays > 0" class="text-base">
                        <span class="font-medium">{{trialDays}}</span>
                        days free, then {{this.customSeats ? 'starts from' : ''}}
                    </p>
                    <p *ngIf="trialDays === 0" class="text-base">Total</p>
                    &nbsp;
                    <p class="text-base font-medium">{{totalPrice | currency: plan.currency:'symbol':'.0-0'}}/month,
                    </p>
                    &nbsp;
                    <p class="text-base">billed {{this.cycle}}ly</p>
                </div>
                }
                <mat-divider></mat-divider>
            </div>
        </div>
        <div class="flex flex-col p-6 items-start justify-between flex-grow">
            <div class="flex flex-col">
                <h6 class="mb-4">What you get</h6>
                <ul class="list-none list-inside">
                    @for (line of plan.included; track $index) {
                    <li class="mb-3 flex text-base">
                        <mat-icon class="mr-2 flex text-bgSuccess align-center" inline>done</mat-icon>
                        {{line}}
                    </li>
                    }
                </ul>
            </div>
            @if (this.public) {
                @if (plan.name === 'pro') {
                    <a class="btn bg-btnPrimary flex justify-center w-full mt-3 hover:scale-105 text-btnSecondary transition-all ease-in-out duration-300 px-3"
                        [href]="this.registerUrl" target="_blank">
                        Start Free Now
                    </a>
                } @else if(plan.name === 'creator') {
                    <a class="btn bg-btnPrimary flex justify-center w-full mt-3 hover:scale-105 text-btnSecondary transition-all ease-in-out duration-300 px-3" 
                        [href]="this.registerUrl" target="_blank">
                        Start Free Now
                    </a>
                } @else if (plan.name === 'enterprise') {
                    <a class="btn bg-btnPrimary flex justify-center w-full mt-3 hover:scale-105 text-btnSecondary transition-all ease-in-out duration-300 px-3"
                        href="https://pies.studio" target="_blank">
                        Contact Us
                    </a>
                }
            } @else {
            @if (plan.name === 'community') {
            <button
                class="btn bg-btnPrimary w-full mt-3 hover:scale-105 text-btnSecondary transition-all ease-in-out duration-300 px-3"
                (click)="activate()">
                Activate
            </button>
            } @else if(plan.name === 'pro') {
            <button
                class="btn bg-btnPrimary w-full mt-3 hover:scale-105 text-btnSecondary transition-all ease-in-out duration-300 px-3"
                (click)="subscribe(plan.id, plan.name, plan.currency, plan.pricing)">
                @if (trialDays > 0) {
                Try for free
                } @else {
                Subscribe Now
                }
            </button>
            } @else if(plan.name === 'enterprise') {
            <button (click)="createRequest('upgrade')"
                class="btn bg-btnPrimary w-full mt-3 hover:scale-105 text-btnSecondary transition-all ease-in-out duration-300 px-3">
                Contact Us
            </button>
            }
            }
        </div>
    </div>
    }
    }
</div>
@if(this.communityPlan && this.showFree) {
<div class="ring-1 ring-btnPrimary rounded p-4 flex justify-between opacity-75">
    <div class="flex flex-col">
        <h6 class="text-base mb-2">Are you an open source developer?</h6>
        <p class="text-sm">
            The PIES Studio Community License allows unlimited public applications for open source developers
        </p>
    </div>
    @if (this.public) {
    <a class="btn btn-sm btn-secondary flex items-center" [href]="this.registerUrl">Get Started</a>
    } @else {
    <button class="btn btn-sm btn-secondary" (click)="activate()">Activate</button>
    }
</div>
}
}