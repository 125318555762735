import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { url } from "../../environments/url";
import { AddOn } from "../model/addon.model";
import { Organization } from "../model/organization.model";
import { User } from "../model/user.model";


@Injectable({
    providedIn: 'root'
})
export class OrgService {

    constructor(
        private http: HttpClient,
    ) { }

    getOrganization(id: string) {
        return this.http.get<Organization>(
            `${environment.backend}${url.organization.get}`,
            {
                params: {
                    id: id,
                }
            }
        );
    }

    getUsersByOrg(clientId: string) {
        return this.http.get<User[]>(
            `${environment.backend}${url.organization.users}`,
            {
                params: {
                    client_id: clientId,
                },
            }
        );
    }

    addUserToOrg(clientId: string, userId: string, role: string, hasPurchaseAccess: boolean, mode: string, invitees: string[],) {
        return this.http.post<{}>(
            `${environment.backend}${url.organization.users}`,
            {
                'user_id': userId,
                'role': role,
                'purchase_access': hasPurchaseAccess,
                'mode': mode,
                'invitees': invitees,
            },
            {
                params: {
                    client_id: clientId,
                }
            }
        )
    }

    updateUserForOrg(clientId: string, userId: string, role: string, hasPurchaseAccess: boolean) {
        return this.http.put<{}>(
            `${environment.backend}${url.organization.users}`,
            {
                'user_id': userId,
                'role': role,
                'purchase_access': hasPurchaseAccess,
            },
            {
                params: {
                    client_id: clientId,
                }
            }
        )
    }

    removeUserFromOrg(clientId: string, userId: string) {
        return this.http.post<{}>(
            `${environment.backend}${url.organization.deleteUser}`,
            {},
            {
                params: {
                    client_id: clientId,
                    user_id: userId,
                }
            }
        )
    }

    getAddOnStatus(client_id: string, type: string, scope: string) {
        return this.http.get<AddOn[]>(
            `${environment.backend}${url.addOn.status}`,
            {
                params: {
                    'client_id': client_id,
                    'type': type,
                    'scope': scope,
                },
            },
        );
    }

    getPiesAIPricing(type: string) {
        return this.http.get<{ tier: string, price: number }[]>(
            `${environment.backend}${url.addOn.pricing}`,
            {
                params: {
                    'type': type,
                },
            },
        );
    }

    purchaseAddOn(clientId: string, type: string, scope: string, metadata: any) {
        return this.http.post<{ url: string }>(
            `${environment.backend}${url.addOn.purchase}`,
            {
                'client_id': clientId,
                'type': type,
                'scope': scope,
                'metadata': metadata,
            },
        );
    }

    cancelAddOn(clientId: string, type: string, scope: string) {
        return this.http.post<null>(
            `${environment.backend}${url.addOn.cancel}`,
            {
                'client_id': clientId,
                'type': type,
                'scope': scope,
            }
        );
    }
}