<!-- List All User Licenses -->
@if (this.mode == 'list') {
<p class="message-sm mb-5">All your created organizations are shown below. You can click on each organization to view
  more
  information, and click on the button below to create a new organization</p>

<div class="flex mb-5">
  <button (click)="newLicense()" mat-flat-button class="btn btn-sm btn-primary px-1 flex items-center">
    <mat-icon class="mr-1">add</mat-icon>
    <span>New Organization</span>
  </button>
</div>

@for (license of licenses; track $index) {
<div
  class="ring-1 ring-borderColor rounded px-6 py-4 flex flex-col md:flex-row md:justify-between md:items-center mb-2">
  <div class="flex flex-col mb-3 md:mb-0">
    <h6 class="text-base mb-2">{{ license.tier === 'creator' ? 'PIES Studio Creators' : license.organization}}</h6>
    <div class="flex flex-col md:flex-row gap-2">
      <div class="flex">
        <div class="badge badge-status"
          [ngClass]="{'status-pending': license.status === 'pending', 'status-active': license.status === 'active', 'status-canceled': license.status === 'canceled'}">
          {{license.status | uppercase}}
        </div>
      </div>
      <h6 class="text-sm font-semibold">{{license.name}}</h6>
      <p class="text-sm flex-grow">Registered <span class="font-medium">{{license.timestamp |
          date:'medium'}}</span>
      </p>
    </div>
  </div>
  <div class="flex gap-4">
    <a *ngIf="license.status === 'active'" [href]="license.redirect" target="_blank"
      class="btn btn-secondary text-btnPrimary px-3 flex items-center">
      <mat-icon class="mr-2">open_in_new</mat-icon>
      Launch
    </a>
    <button class="btn btn-secondary text-btnPrimary px-5 border" (click)="viewLicense(license.client_id)">View</button>
  </div>
</div>
}
} @else if (this.mode == 'view') { <!-- View License Details Section -->

  @if (this.condensed) {
    <h6 class="my-4">License Details</h6>
  } @else if(this.status !== 'pending') {
    <p class="message-sm mb-5">Your platform license details are shown below. You can easily modify or deactivate your license</p>
  }

  @if (this.status !== 'pending') {
    <section class="grid grid-cols-1 ring-1 ring-borderColor rounded mb-4 divide-y">
      <div class="w-full" *ngIf="this.tier !== 'free'">
        <div class="flex flex-col py-3 px-5">
          <span class="text-xs mb-2">Organization Name</span>
          <div class="flex items-center">
            <mat-icon class="mr-1 text-buttonAlternate">corporate_fare</mat-icon>
            <h6 class="text-lg mb-0">{{org}}</h6>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 divide-x" [ngClass]="{'ring-1 ring-borderColor': this.tier === 'free'}">
        <div class="flex flex-col py-3 px-5">
          <span class="text-xs mb-2">License Type</span>
          <div class="flex items-center">
            <mat-icon class="mr-1 text-buttonAlternate">license</mat-icon>
            <h6 class="text-lg mb-0">{{name}}</h6>
          </div>
        </div>
        <div class="flex flex-col py-3 px-6">
          <span class="text-xs mb-2">Status</span>
          <div class="flex items-center">
            @if(this.status === "pending") {
            <mat-icon class="mr-1 text-yellow-500">error</mat-icon>
            } @else if(this.status === "inactive" || this.status === "canceled") {
            <mat-icon class="mr-1 text-buttonDangerColor">error</mat-icon>
            } @else if(this.status === "active") {
            <mat-icon class="mr-1 text-bgSuccess">check_circle</mat-icon>
            }
            <h6 class="text-lg mb-0">{{status | titlecase}}</h6>
          </div>
        </div>
        <div class="flex flex-col py-3 px-5">
          <span class="text-xs mb-2">Valid Till</span>
          <h6 class="text-lg mb-0">{{validity ? (validity | date) : '--'}}</h6>
        </div>
      </div>
      @if (!this.isCancelled && !this.offline) {
      <div class="grid grid-cols-1 md:grid-cols-3 divide-x">
        <div class="flex flex-col py-3 px-5">
          <span class="text-xs mb-2">Actions</span>
          <div class="flex items-center">
            @if (this.tier === 'creator') {
            <button (click)="upgradeToProLicense()"
              class="btn btn-secondary text-btnPrimary px-3 border border-btnPrimary mr-3">
              Upgrade License
            </button>
            } @else {
            <button class="btn btn-secondary text-btnPrimary px-3 border border-btnPrimary mr-3"
              (click)="modifySubscription()">
              Modify Subscription
            </button>
            <button class="btn btn-secondary text-btnPrimary px-3 border border-btnPrimary mr-3"
              (click)="switchBillingCycle()">
              Change Billing Cycle
            </button>
            }
          </div>
        </div>
      </div>
      }
    </section>

    @if(this.isCancelled) {
    <div class="ring-1 ring-borderColor rounded flex items-center py-3 px-5 mb-4">
      <mat-icon class="mr-5 text-bgWarning min-w-6">warning</mat-icon>
      <div class="flex flex-col">
        <h6 class="text-base mb-2">Scheduled For Cancellation</h6>
        <p class="message-sm mb-4">
          This subscription has been scheduled for cancellation and will be cancelled permanently by <b
            class="font-medium">{{this.validity| date:'medium'}}</b>.
        </p>
        <div class="flex">
          <button (click)="restoreSubscription()"
            class="btn btn-secondary text-btnPrimary border border-btnPrimary px-3">Restore Subscription</button>
        </div>
      </div>
    </div>
    }

    @if (this.status === 'active') {
      <!-- Identity and Access Management. Add users from here -->
    @if (!this.condensed) {

      @if (this.tier !== 'free') {
        <app-iam class="mb-4"></app-iam>
      } @else {
          <section class="flex flex-col mb-4">
            <h6 class="mb-3">Details</h6>
            <div class="flex items-center ring-1 ring-borderColor rounded-md mb-3 divide-x">
              <div class="flex flex-col py-3 px-5">
                <span class="text-xs mb-2">Client ID</span>
                <div class="flex items-center">
                  <p class="text-base font-medium text-primary mb-0 mr-2">{{clientId}}</p>
                  <mat-icon matTooltip="Copy" role="button" class="cursor-pointer"
                    (click)="copy(this.clientId)">content_copy</mat-icon>
                </div>
              </div>
              <div *ngIf="this.tier === 'free'" class="flex flex-col py-3 px-5">
                <span class="text-xs mb-2">Client Secret</span>
                @if(this.clientSecret !== '') {
                <div class="flex items-center">
                  <p class="text-base font-medium text-primary mb-0 mr-2">{{clientSecret}}</p>
                  <mat-icon role="button" matTooltip="Copy" class="cursor-pointer"
                    (click)="copy(this.clientSecret)">content_copy</mat-icon>
                </div>
                } @else {
                <div class="flex items-center">
                  <p class="text-base font-medium text-primary mb-0 mr-2">
                    &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                  </p>
                  <mat-icon matTooltip="Regenerate" class="cursor-pointer" role="button"
                    (click)="regenrateClientSecret()">refresh</mat-icon>
                </div>
                }
              </div>
              @if (this.clientSecret !== '') {
              <div class="flex flex-col py-3 px-5">
                <span class="text-xs mb-2">Download</span>
                <a role="button" (click)="downloadDockerCompose()"
                  class="cursor-pointer text-base font-medium text-buttonAlternate mb-0">Docker Compose</a>
              </div>
              }
            </div>
            @if(this.clientSecret !== '') {
            <div class="rounded-md flex items-center w-full p-3 banner mb-3">
              <mat-icon class="mr-2">info</mat-icon>
              <p class="text-sm font-medium text-primary">The client secret will only be visible till the session is active or
                the page is reloaded. Make sure you note it down in a safe place in order to use it while setting up the platform.
              </p>
            </div>
            }
          </section>
          <section *ngIf="this.tier === 'free'" class="flex flex-col mb-4">
            <h6 class="mb-3">Redirect URI</h6>
            <div class="flex items-center ring-1 ring-borderColor rounded-md mb-3 divide-x">
              <div class="flex flex-col py-3 px-5">
                <span class="text-sm mb-3">Update the redirect URI given below for successful authentication if your installation
                  is being run on different uri or port</span>
                <div class="flex items-center">
                  <input type="text" class="block mb-2 text-xs font-medium text-fontPrimary" [value]="this.redirectUri"
                    (input)="setRedirectUri($event)" />
                  <a role="button" (click)="updateRedirectUri()"
                    class="cursor-pointer bg-buttonAlternate btn btn-primary px-3 py-2 rounded-md mb-2 ms-2">Update</a>
                </div>
              </div>
            </div>
          </section>
        }
        <section class="flex flex-col mb-4">
          <h6 class="mb-3">Help</h6>
          <div class="flex items-center ring-1 ring-gray-200 rounded-md mb-3 divide-x">
            <div *ngIf="this.tier === 'free'" class="flex flex-col py-3 px-5">
              <span class="text-xs mb-2">Set-up</span>
              <a role="button" href="https://docs.pies.io/pies-studio-developer-guide/v0.98/pies-studio-community-version-setup"
                target="_blank" class="cursor-pointer text-base font-medium text-buttonAlternate mb-0">Set-up Guide</a>
            </div>
            <div class="flex flex-col py-3 px-5">
              <span class="text-xs mb-2">Usage</span>
              <a role="button" href="https://docs.pies.io" target="_blank"
                class="cursor-pointer text-base font-medium text-buttonAlternate mb-0">Usage Guide</a>
            </div>
            <div class="flex flex-col py-3 px-5">
              <span class="text-xs mb-2">Support</span>
              <a role="button" (click)="createRequest('support')"
                class="cursor-pointer text-base font-medium text-buttonAlternate mb-0">Raise a ticket</a>
            </div>
          </div>
        </section>
        <section class="flex flex-col mb-4">
          @if (this.tier === 'free') {
          <h6 class="mb-3">Actions</h6>
          <div class="flex items-stretch gap-x-4">
            <div class="ring-1 ring-borderColor rounded-md p-5 mb-3 flex-1">
              <h2 class="mb-2 text-2xl font-bold">Upgrade to Pro!</h2>
              <p class="mb-3 text-base">Switch to our professional license for to unlock new and exciting features
              </p>
              <a role="button" (click)="createRequest('upgrade')"
                class="cursor-pointer bg-buttonAlternate btn btn-primary px-3 py-2 rounded-md">Request an upgrade</a>
            </div>
            <div class="ring-1 ring-borderColor rounded-md p-5 mb-3 flex-1">
              <h2 class="mb-2 text-2xl font-bold">Upgrade to Enterprise!</h2>
              <p class="mb-3 text-base">Switch to our enterprise license for a personalised service and exciting new features
              </p>
              <a role="button" (click)="createRequest('upgrade')"
                class="cursor-pointer bg-buttonAlternate btn btn-primary px-3 py-2 rounded-md">Request an upgrade</a>
            </div>
          </div>
          }
          <div *ngIf="this.tier === 'free'" class="ring-1 ring-gray-200 rounded-md p-5 mb-3">
            <h6 class="text-base mb-2">Deactivate License</h6>
            <p class="mb-3 text-sm">You can deactivate your license if you no longer wish to use the platform. Deactivating your
              license will also disable your access to all applications in the portal.</p>
            <a role="button" (click)="createRequest('deactivate')"
              class="cursor-pointer btn btn-primary bg-buttonDangerColor px-3 py-2 rounded-md">Request
              Deactivation</a>
          </div>
        </section>
        <div class="flex">
          <button class="btn btn-secondary text-btnPrimary border px-5" (click)="backToLicenses()">Back</button>
        </div>
      }
    }
  } @else if (this.status === 'pending') {
    <section class="flex flex-col mt-0 mb-4">
      <h4 class="mb-2 mt-3 text-xl font-medium">Choose the edition that's right for you</h4>
      <p class="mb-2 text-base">Please choose and activate your edition below to start developing applications</p>
      <div class="grid grid-cols-1">
        <app-pricing [showCreator]="false" [public]="false" [clientId]="this.clientId" [showFree]="false"></app-pricing>
      </div>
    </section>
  }
} @else if(this.mode === 'create') {
<section class="flex flex-col mt-0 mb-4 items-center">
  <div class="flex flex-col items-center">
    <h4 class="mb-2 mt-3 text-xl font-medium">Choose the edition that's right for you</h4>
    <p class="mb-2 text-base">Please choose and activate your edition below to start developing applications</p>
    <app-pricing [showCreator]="false" [public]="false" [clientId]="''" [showFree]="false"
      class="mb-4 w-full"></app-pricing>
    <button class="btn btn-secondary text-btnPrimary border px-5 w-full" (click)="backToLicenses()">
      I'll do it later
    </button>
  </div>
</section>
} @else if (this.mode === 'upgrade') {
<section class="flex flex-col mt-0 mb-4">
  <h4 class="mb-2 mt-3 text-xl font-medium">Choose the edition that's right for you</h4>
  <p class="mb-2 text-base">Please choose and activate your edition below to start developing applications</p>
  <div class="grid grid-cols-1">
    <app-pricing [showCreator]="false" [public]="false" [clientId]="''" [showFree]="false"></app-pricing>
    <button class="btn btn-secondary text-btnPrimary border px-5 w-full" (click)="backToLicenses()">
      I'll do it later
    </button>
  </div>
</section>
}